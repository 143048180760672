var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.isMobile)?_c('div',{staticClass:"flex justify-center",attrs:{"id":"pc_nav"}},[_c('div',{staticClass:"w-4/5 flex justify-around"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"navItemLine self-center opacity-0",class:_vm.navItemHover2 === true || _vm.navItemActive === 'section2'
            ? 'opacity-100'
            : 'opacity-0'}),_c('a',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({ element: "#section2", offset: -70 }),expression:"{ element: `#section2`, offset: -70 }"}],staticClass:"self-center font-weight-700 mx-3 hover:text-primary-100",class:_vm.navItemActive === 'section2' ? 'text-primary-100' : '',attrs:{"href":""},on:{"mouseenter":function($event){_vm.navItemHover2 = true},"mouseleave":function($event){_vm.navItemHover2 = false}}},[_vm._v("美好入心\n      ")]),_c('div',{staticClass:"navItemLine self-center opacity-0",class:_vm.navItemHover2 === true || _vm.navItemActive === 'section2'
            ? 'opacity-100'
            : 'opacity-0'})]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"navItemLine self-center opacity-0",class:_vm.navItemHover4 === true || _vm.navItemActive === 'section4'
            ? 'opacity-100'
            : 'opacity-0'}),_c('a',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({ element: "#section4", offset: -70 }),expression:"{ element: `#section4`, offset: -70 }"}],staticClass:"self-center font-weight-700 mx-3 hover:text-primary-100",class:_vm.navItemActive === 'section4' ? 'text-primary-100' : '',attrs:{"href":""},on:{"mouseenter":function($event){_vm.navItemHover4 = true},"mouseleave":function($event){_vm.navItemHover4 = false}}},[_vm._v("水岸富翁\n      ")]),_c('div',{staticClass:"navItemLine self-center opacity-0",class:_vm.navItemHover4 === true || _vm.navItemActive === 'section4'
            ? 'opacity-100'
            : 'opacity-0'})]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"navItemLine self-center opacity-0",class:_vm.navItemHover6 === true || _vm.navItemActive === 'section6'
            ? 'opacity-100'
            : 'opacity-0'}),_c('a',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({ element: "#section6", offset: -100 }),expression:"{ element: `#section6`, offset: -100 }"}],staticClass:"self-center font-weight-700 mx-3 hover:text-primary-100",class:_vm.navItemActive === 'section6' ? 'text-primary-100' : '',attrs:{"href":""},on:{"mouseenter":function($event){_vm.navItemHover6 = true},"mouseleave":function($event){_vm.navItemHover6 = false}}},[_vm._v("花樣生活\n      ")]),_c('div',{staticClass:"navItemLine self-center opacity-0",class:_vm.navItemHover6 === true || _vm.navItemActive === 'section6'
            ? 'opacity-100'
            : 'opacity-0'})]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"navItemLine self-center opacity-0",class:_vm.navItemHover8 === true || _vm.navItemActive === 'section8'
            ? 'opacity-100'
            : 'opacity-0'}),_c('a',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({ element: "#section8", offset: -100 }),expression:"{ element: `#section8`, offset: -100 }"}],staticClass:"self-center font-weight-700 mx-3 hover:text-primary-100",class:_vm.navItemActive === 'section8' ? 'text-primary-100' : '',attrs:{"href":""},on:{"mouseenter":function($event){_vm.navItemHover8 = true},"mouseleave":function($event){_vm.navItemHover8 = false}}},[_vm._v("捷運熱點\n      ")]),_c('div',{staticClass:"navItemLine self-center opacity-0",class:_vm.navItemHover8 === true || _vm.navItemActive === 'section8'
            ? 'opacity-100'
            : 'opacity-0'})]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"navItemLine self-center opacity-0",class:_vm.navItemHover9 === true || _vm.navItemActive === 'section9'
            ? 'opacity-100'
            : 'opacity-0'}),_c('a',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({ element: "#section9", offset: -100 }),expression:"{ element: `#section9`, offset: -100 }"}],staticClass:"self-center font-weight-700 mx-3 hover:text-primary-100",class:_vm.navItemActive === 'section9' ? 'text-primary-100' : '',attrs:{"href":""},on:{"mouseenter":function($event){_vm.navItemHover9 = true},"mouseleave":function($event){_vm.navItemHover9 = false}}},[_vm._v("空間自由\n      ")]),_c('div',{staticClass:"navItemLine self-center opacity-0",class:_vm.navItemHover9 === true || _vm.navItemActive === 'section9'
            ? 'opacity-100'
            : 'opacity-0'})])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }