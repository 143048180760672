<template>
  <div class="section1 mb-20 md:mb-32">
    <div v-if="!isMobile" class="flex-col h-full">
      <div class="flex h-full">
        <div class="w-1/3">
          <div class="flex mb-1">
            <img
              src="./s1/pc_style_b1.png"
              alt=""
              class="mr-1 img-scale-0 left-up"
            />
            <img
              src="./s1/pc_style_b.png"
              alt=""
              class="mr-1 img-scale-0 right-down"
            />
            <img
              src="./s1/pc_style_g2.png"
              alt=""
              class="mr-1 img-scale-0 right-up"
            />
            <img
              src="./s1/pc_style_b.png"
              alt=""
              class="mr-1 img-scale-0 right-down"
            />
          </div>
          <div class="flex mb-1">
            <img
              src="./s1/pc_style_g3.png"
              alt=""
              class="mr-1 img-scale-0 left-down"
            />
            <img
              src="./s1/pc_style_b3.png"
              alt=""
              class="mr-1 img-scale-0 left-down"
            />
          </div>
          <div class="flex mb-1">
            <img
              src="./s1/pc_style_g4.png"
              alt=""
              class="mr-1 img-scale-0 left-up"
            />
            <img
              src="./s1/pc_style_b.png"
              alt=""
              class="mr-1 img-scale-0 right-down"
            />
          </div>
          <img
            src="./s1/pc_style_b.png"
            alt=""
            class="mr-1 img-scale-0 right-down"
          />
        </div>
        <div class="w-1/3 flex justify-center items-center relative">
          <img
            class="absolute bottom-28"
            data-aos="fade-up"
            data-aos-delay="500"
            src="./s1/diamond-blink.svg"
            alt=""
          />
          <!-- <img class="absolute bottom-28" src="./s1/k.png" alt="" /> -->
          <img
            class="absolute bottom-28"
            data-aos="fade-up"
            data-aos-delay="500"
            src="./s1/pc_cristal.png"
            alt=""
          />
          <div class="flex-col z-50">
            <div
              data-aos="fade-up"
              data-aos-delay="100"
              class="flex justify-center"
            >
              <img src="./s1/pc_logo.png" alt="" class="mb-4" />
            </div>
            <div
              data-aos="fade-up"
              data-aos-delay="200"
              class="flex justify-center text-1.3 tracking-widest font-weight-600"
            >
              <span>三重新都心</span>
              <img class="w-6 mx-6" src="./s1/pc_title_x.svg" alt="" />
              <span>臨水生活圈</span>
            </div>
            <div
              data-aos="fade-up"
              data-aos-delay="300"
              class="flex justify-center items-center text-2.4 font-weight-600"
            >
              <div class="line w-1/4"></div>
              <span class="ml-2">14-17</span>
              <span class="mr-2 pt-2 mt-6 text-0.8">坪</span>
              <div class="line w-1/4"></div>
            </div>
            <div
              class="flex justify-center text-1 tracking-superwide font-weight-500"
            >
              <span data-aos="fade-up" data-aos-delay="400" class="mb-200"
                >微型飯店宅 裝潢全到位</span
              >
            </div>
            <div
              data-aos="fade-up"
              data-aos-delay="500"
              class="flex justify-center text-1.3 tracking-wider font-weight-600 margin"
            >
              <span class="px-7" style="border: solid 1px">2278-2685</span>
            </div>
          </div>
        </div>
        <div class="w-1/3 flex justify-end items-end">
          <div class="">
            <div class="flex mb-1 justify-end">
              <img
                src="./s1/pc_style_b1.png"
                alt=""
                class="img-scale-0 left-up"
              />
            </div>
            <div class="flex mb-1 justify-end">
              <img
                src="./s1/pc_style_b1.png"
                alt=""
                class="img-scale-0 left-up"
              />
              <img
                src="./s1/pc_style_g1.png"
                alt=""
                class="ml-1 img-scale-0 right-down"
              />
            </div>
            <div class="flex mb-1 justify-end">
              <img
                src="./s1/pc_style_b2.png"
                alt=""
                class="img-scale-0 right-up"
              />
              <img
                src="./s1/pc_style_g2.png"
                alt=""
                class="ml-1 img-scale-0 left-up"
              />
            </div>
            <div class="flex justify-end">
              <img
                src="./s1/pc_style_b1.png"
                alt=""
                class="img-scale-0 left-up"
              />
              <img
                src="./s1/pc_style_g3.png"
                alt=""
                class="ml-1 img-scale-0 left-down"
              />
              <img
                src="./s1/pc_style_b1.png"
                alt=""
                class="ml-1 img-scale-0 left-up"
              />
              <img
                src="./s1/pc_style_b.png"
                alt=""
                class="ml-1 img-scale-0 right-down"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Mobile -->
    <div v-if="isMobile" class="h-full">
      <div class="h-1/3vh flex">
        <div class="w-3/5">
          <div class="flex">
            <img
              src="./s1/pc_style_b1.png"
              alt=""
              class="left-up h-1/4 w-1/4 img-scale-0"
            />
            <!-- slideLeftUp -->
            <!-- style="transform: rotate(180deg)" -->
            <img
              src="./s1/pc_style_b.png"
              alt=""
              class="right-down h-1/4 w-1/4 img-scale-0"
            />
            <img
              src="./s1/pc_style_g2.png"
              alt=""
              class="right-up h-1/4 w-1/4 img-scale-0"
            />
            <img
              src="./s1/pc_style_b.png"
              alt=""
              class="right-down h-1/4 w-1/4 img-scale-0"
            />
          </div>
          <div class="flex">
            <img
              src="./s1/pc_style_g3.png"
              alt=""
              class="left-down h-1/4 w-1/4 img-scale-0"
            />
            <img
              src="./s1/pc_style_b3.png"
              alt=""
              class="left-down h-1/4 w-1/4 img-scale-0"
            />
          </div>
          <div class="flex">
            <img
              src="./s1/pc_style_g4.png"
              alt=""
              class="left-up h-1/4 w-1/4 img-scale-0"
            />
            <img
              src="./s1/pc_style_b.png"
              alt=""
              class="right-down h-1/4 w-1/4 img-scale-0"
            />
          </div>
          <img
            src="./s1/pc_style_b.png"
            alt=""
            class="right-down h-1/4 w-1/4 img-scale-0"
          />
        </div>
      </div>
      <div class="h-1/3vh relative">
        <!--  -->
        <img
          class="absolute top-14"
          data-aos="fade-up"
          data-aos-delay="500"
          src="./s1/diamond-blink.svg"
          alt=""
        />
        <!-- <img class="absolute top-14" src="./s1/k.png" alt="" /> -->
        <img
          class="absolute top-14"
          data-aos="fade-up"
          data-aos-delay="500"
          style="z-index: -1"
          src="./s1/pc_cristal.png"
          alt=""
        />
        <!--  -->
        <div class="flex-col z-50">
          <div class="mx-auto w-7/10vw">
            <img
              data-aos="fade-up"
              data-aos-delay="100"
              src="./s1/pc_logo.png"
              alt=""
              class="mb-4"
            />
          </div>
          <div
            data-aos="fade-up"
            data-aos-delay="200"
            class="flex justify-center text-5 tracking-widest font-weight-600"
          >
            <span>三重新都心</span>
            <img class="w-6 mx-6" src="./s1/mobile_close.svg" alt="" />
            <span>臨水生活圈</span>
          </div>
          <div
            data-aos="fade-up"
            data-aos-delay="300"
            class="flex justify-center items-center text-2.4 font-weight-600"
          >
            <div class="line w-1/4"></div>
            <span class="ml-3 text-8">14-17</span>
            <span class="mr-3 mt-3">坪</span>
            <div class="line w-1/4"></div>
          </div>
          <div
            data-aos="fade-up"
            data-aos-delay="400"
            class="flex justify-center text-4.5 tracking-superwide font-weight-500"
          >
            <span class="mb-5">微型飯店宅 裝潢全到位</span>
          </div>
          <div
            data-aos="fade-up"
            data-aos-delay="500"
            class="flex justify-center text-6.5 tracking-wider font-weight-600"
          >
            <span class="px-7" style="border: solid 2px">2278-2685</span>
          </div>
        </div>
      </div>
      <div class="h-1/3vh flex justify-end bottom">
        <div class="w-3/5">
          <div class="flex justify-end">
            <img
              src="./s1/pc_style_b1.png"
              alt=""
              class="left-up h-1/4 w-1/4 img-scale-0"
            />
            <!-- <img
              data-aos="fade"
              data-aos-delay="200"
              src="./s1/pc_style_b.png"
              alt=""
              style="transform: rotate(180deg)"
              class="h-1/4 w-1/4"
            /> -->
          </div>
          <div class="flex justify-end">
            <img
              src="./s1/pc_style_b1.png"
              alt=""
              class="left-up h-1/4 w-1/4 img-scale-0"
            />
            <img
              data-aos="fade"
              data-aos-delay="800"
              src="./s1/pc_style_g1.png"
              alt=""
              class="right-down h-1/4 w-1/4 img-scale-0"
            />
          </div>
          <div class="flex justify-end">
            <img
              src="./s1/pc_style_b2.png"
              alt=""
              class="right-up h-1/4 w-1/4 img-scale-0"
            />
            <img
              src="./s1/pc_style_g2.png"
              alt=""
              class="right-up h-1/4 w-1/4 img-scale-0"
            />
          </div>
          <div class="flex justify-end">
            <img
              src="./s1/pc_style_b1.png"
              alt=""
              class="left-up h-1/4 w-1/4 img-scale-0"
            />
            <img
              src="./s1/pc_style_g3.png"
              alt=""
              class="left-down h-1/4 w-1/4 img-scale-0"
            />
            <img
              src="./s1/pc_style_b1.png"
              alt=""
              class="left-up h-1/4 w-1/4 img-scale-0"
            />
            <img
              src="./s1/pc_style_b.png"
              alt=""
              class="right-down h-1/4 w-1/4 img-scale-0"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.img-scale-0 {
  transform: scale(0);
}
.slideLeftUp {
  transform-origin: bottom right;
  animation-name: temp;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}
.slideLeftDown {
  transform-origin: top right;
  animation-name: temp;
  animation-duration: 3s;
  animation-fill-mode: forwards;
}
.slideRightDown {
  transform-origin: top left;
  animation-name: temp;
  animation-duration: 3s;
  animation-fill-mode: forwards;
}
.slideRightup {
  transform-origin: bottom left;
  animation-name: temp;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}
@keyframes temp {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

.line {
  background-color: black;
  height: 1px;
}
@import '@/assets/style/function.scss';
.section1 {
  padding-top: 70px;
  height: 100vh;
  min-height: size(900);
  max-height: size(1080);
}
.margin {
  margin-top: 20px;
}

/* 螢幕尺寸標準 */
// .bg-diamond {
//   background-image: url('./s1/pc_cristal.png');
//   background-repeat: no-repeat;
//   background-size: 100%;
//   background-position: center center;
// }
/* 平板尺寸 */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
}

/* 手機尺寸 */
@media only screen and (max-width: 767px) {
  .section1 {
    padding-top: 0;
    height: calc(100vh - 63px);
    min-height: size-m(604);
    max-height: size-m(750);
  }
  .bottom {
    margin-top: 6%;
  }
}

// 避免內容電腦過渡平板時，設計未考量的調整
@media only screen and (min-width: 1025px) and (max-width: 1199.98px) {
}

// 避免過度到 1280 x 720 時，設計未考量的調整
@media only screen and (min-width: 1025px) and (max-width: 1280px) {
}
</style>

<script>
// @ is an alias to /src
import { isMobile } from '@/utils'

export default {
  name: 'section1',

  data() {
    return {
      isMobile,
    }
  },

  methods: {},

  mounted() {
    let interval
    // interval = setInterval(() => {
    //   this.setAnimateToEn()
    // }, 1000)

    setTimeout(() => {
      clearInterval(interval)
    }, 3000)
    setTimeout(() => {
      $('.left-up').addClass('slideLeftUp')
    }, 100)
    setTimeout(() => {
      $('.left-down').addClass('slideLeftDown')
    }, 400)
    setTimeout(() => {
      $('.right-down').addClass('slideRightDown')
    }, 600)
    setTimeout(() => {
      $('.right-up').addClass('slideRightup')
    }, 300)
  },

  created() {},
}
</script>
