<template>
  <div class="section7">
    <div
      v-if="!isMobile"
      class="flex justify-between w-full h-full"
    >
      <div class="w-2/5">
        <div data-aos="fade" data-aos-delay="400" class="w-full relative">
          <img
            src="./s7/pc_img_1.jpg"
            alt=""
            class="mb-4 w-full h-3/5 object-cover"
          />
          <span
            class="absolute bottom-2 right-4 text-white z-10 font-weight-700 text-1"
          >
            IKEA
          </span>
        </div>
        <div data-aos="fade" data-aos-delay="400" class="w-full h-1/3">
          <div class="w-3/5 inline-block h-full relative">
            <img class="w-full h-full pr-2" src="./s7/pc_img_2.jpg" alt="" />
            <span
              class="absolute bottom-2 right-4 text-white z-10 font-weight-700 text-1"
            >
              宏匯廣場
            </span>
          </div>
          <div
            data-aos="fade"
            data-aos-delay="400"
            class="w-2/5 inline-block h-full relative"
          >
            <img class="w-full h-full pl-2" src="./s7/pc_img_3.jpg" alt="" />
            <span
              class="absolute bottom-2 right-2 text-white z-10 font-weight-700 text-1"
            >
              家樂福
            </span>
          </div>
        </div>
        <!-- <div class="flex-col" style="width: 40vw; height: 40vw">
          <img src="./s7/pc_img_1.jpg" alt="" class="mb-4" />
          <div class="flex w-full">
            <img src="./s7/pc_img_2.jpg" alt="" class="mr-4" />
            <img src="./s7/pc_img_3.jpg" alt="" />
          </div>
        </div> -->
      </div>
      <div data-aos="fade" data-aos-delay="300" class="w-1/2 bg-s7">
        <img
          src="./s7/pc_style_bg1.png"
          alt=""
          style="width: 17vw; margin-left: 43%; margin-top: -8.4%"
        />
        <div class="flex-col" style="margin-top: 23%; margin-left: 10%">
          <p
            data-aos="fade-up"
            data-aos-delay="400"
            class="font-weight-600 text-1.4 text-left"
          >
            FASHION LIFESTYLE
          </p>
          <p
            data-aos="fade-up"
            data-aos-delay="500"
            class="font-weight-900 text-1.8 text-left tracking-superwide"
            style="letter-spacing: 0.2em"
          >
            無限便利 環繞你
          </p>
          <div
            data-aos="fade-up"
            data-aos-delay="600"
            class="font-weight-500 text-1.1 text-left tracking-widest leading-loose"
            style="margin-top: 18.5%; width: 80%"
          >
            <p>
              左擁新莊副都心特區，右抱三重潤泰CITYLINK商城建設繁錦。新莊IKEA、家樂福、湯城廣場、UNIQLO旗艦店圍繞生活半徑，時尚與便捷機能一次同步。
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- Mobile -->
    <div v-if="isMobile" class="mb-8">
      <div data-aos="fade" data-aos-delay="300" class="mb-bg-s7 relative">
        <img
          src="./s7/pc_style_bg1.png"
          alt=""
          class="absolute top-0 right-0"
          style="width: 35vw; margin-left: 43%"
        />
        <div class="flex flex-col" style="width: 93.7vw; padding-bottom: 24%">
          <div
            class="flex items-center"
            style="margin-top: 9%; margin-left: 6%"
          >
            <div class="flex flex-col mr-3">
              <p
                data-aos="fade-up"
                data-aos-delay="400"
                class="font-weight-600 text-6 text-left"
              >
                FASHION LIFESTYLE
              </p>
              <p
                data-aos="fade-up"
                data-aos-delay="500"
                class="font-weight-900 text-6.5 text-left"
                style="letter-spacing: 0.15em"
              >
                無限便利 環繞你
              </p>
            </div>
          </div>
          <div
            data-aos="fade-up"
            data-aos-delay="600"
            class="font-weight-500 text-4 text-left tracking-widest leading-loose"
            style="margin-top: 5%; margin-left: 6%"
          >
            <p>
              左擁新莊副都心特區，右抱三重潤泰CITYLINK商城建設繁錦。新莊IKEA、家樂福、湯城廣場、UNIQLO旗艦店圍繞生活半徑，時尚與便捷機能一次同步。
            </p>
          </div>
        </div>
      </div>
      <div
        data-aos="fade"
        data-aos-delay="400"
        class="mx-auto"
        style="width: 90vw; margin-top: -15%"
      >
        <div class="w-full relative" style="">
          <img src="./s7/pc_img_1.jpg" alt="" class="mb-2 object-cover" />
          <span
            class="absolute bottom-2 right-4 text-white z-10 font-weight-700"
          >
            IKEA
          </span>
        </div>
        <div class="w-full flex">
          <div class="w-3/5 h-full relative mr-2">
            <img class="" src="./s7/pc_img_2.jpg" alt="" />
            <span
              class="absolute bottom-2 right-4 text-white z-10 font-weight-700"
            >
              宏匯廣場
            </span>
          </div>
          <div class="w-2/5 h-full relative object-cover">
            <img style="height: 28.3vw" src="./s7/pc_img_3.jpg" alt="" />
            <span
              class="absolute bottom-2 right-2 text-white z-10 font-weight-700"
            >
              家樂福
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
@import '@/assets/style/function.scss';
/* 螢幕尺寸標準 */
.section7{
      margin-bottom: 80px;
}
.bg-s7 {
  background-image: url('./s7/pc_style_bg2.svg');
  background-repeat: no-repeat;
  background-size: 86%;
  background-position: 30% -13%;
}

// Mobile
.mb-bg-s7 {
  background-image: url('./s7/pc_style_bg2.svg');
  background-repeat: no-repeat;
  background-size: 97%;
  background-position: 50% 5.3vw;
}

/* 平板尺寸 */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
}

/* 手機尺寸 */
@media only screen and (max-width: 767px) {
  
  .bottom-2{
        font-size: 3.2vw;
  }
  .swiper-container {
    height: 37vh;
    width: 100vw;
    margin: 0;
    .slide {
      height: 100%;
      width: 100%;
      background-size: cover;
      .caption {
        position: absolute;
        z-index: 1;
        color: #fff;
        right: 1vw;
        bottom: 0.8vw;
        font-size: 3.2vw;
        font-weight: bold;
        text-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
      }
    }
    .swiper-button-prev {
      // left: size(500);
      left: 0;
      bottom: 0;
      width: size(80);
      height: size(80);
      color: #fff;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: left;
      // background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E") !important;

      &::after {
        content: '';
      }
    }
    .swiper-button-next {
      right: 0;
      color: #fff;
      width: size(80);
      height: size(80);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: right;
      // background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E") !important;

      &::after {
        content: '';
      }
    }
  }
  ::v-deep .swiper-pagination {
    position: relative;
    .swiper-pagination-bullet {
      margin: 0 10px;
      border-radius: 50%;
      background: transparent;
      border: 1px solid white;
      width: size(28);
      height: size(28);
      opacity: 1;
      &.swiper-pagination-bullet-active {
        background: white;
      }
    }
  }
}

// 避免內容電腦過渡平板時，設計未考量的調整
@media only screen and (min-width: 1025px) and (max-width: 1199.98px) {
}

// 避免過度到 1280 x 720 時，設計未考量的調整
@media only screen and (min-width: 1025px) and (max-width: 1280px) {
}
</style>

<script>
// @ is an alias to /src
import { isMobile } from '@/utils'

export default {
  name: 'section7',
  data() {
    return {
      isMobile,
    }
  },

  methods: {},

  mounted() {},

  created() {},
}
</script>
