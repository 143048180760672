<template>
  <div class="section5 bg-secondary-100">
    <div v-if="!isMobile" class="flex justify-end" style="height: 45vw">
      <img src="./s5/pc_style_bg1.png" alt="睿暘沁_背景" class="bg">
      <div
        data-aos="fade"
        data-aos-delay="300"
        class="flex-col bg-s5-left"
        style="height: 43vw; width: 43vw"
      >
        <p
          data-aos="fade-up"
          data-aos-delay="400"
          class="text-white font-weight-600 text-1.4 text-left"
          style="margin-top: 23%"
        >
          LIVE IN UPTOWN
        </p>
        <p
          data-aos="fade-up"
          data-aos-delay="500"
          class="text-third-100 font-weight-900 text-1.8 text-left tracking-superwide"
          style="letter-spacing: 0.2em"
        >
          都心願景 向著你
        </p>
        <div
          data-aos="fade-up"
          data-aos-delay="600"
          class="text-white font-weight-500 text-1.1 text-left tracking-widest leading-loose"
          style="margin-top: 33%; width: 80%"
        >
          <p>
            新北第二行政園區近兩萬坪建設預計在2026年完工帶來公部門與東森影視園區就業發展，加上新北科技園區千億產值，全臺白領菁英同步匯聚於此
          </p>
        </div>
      </div>
      <div
        data-aos="fade"
        data-aos-delay="300"
        class="bg-s5-right flex justify-end items-center"
        style="height: 43vw; width: 43vw"
      >
        <div
          data-aos="fade"
          data-aos-delay="300"
          id="swiper_container"
          class="mt-36"
        >
          <swiper
            :options="swiperOptions"
            ref="swiperSection5"
            @slideChangeTransitionStart="slidechange"
          >
            <swiper-slide
              class="slide"
              v-for="(slide, i) in slides"
              v-bind:key="i"
              v-bind:style="{
                backgroundImage: `url(${slide.img})`,
              }"
            >
              <div class="caption">
                {{ slide.caption }}
              </div>
            </swiper-slide>
          </swiper>
          <div class="flex justify-between mt-5 mr-1" style="width: 39vw">
            <img
              @click="prev()"
              style="width: 3vw"
              class="w-12 cursor-pointer"
              src="./s4/pc_arrow_l.svg"
              alt=""
            />
            <div class="swiper-pagination" slot="pagination"></div>
            <img
              @click="next()"
              style="width: 3vw"
              class="w-12 cursor-pointer"
              src="./s4/pc_arrow_r.svg"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>

    <!-- Mobile -->
    <div v-if="isMobile" class="pb-5" style="margin-top: -1px">
      <div data-aos="fade" data-aos-delay="300" class="h-1/2 mb-bg-s5">
        <div class="flex flex-col" style="height: 70vw; width: 93.7vw">
          <div
            class="flex items-center"
            style="margin-top: 8vw; margin-left: 6%"
          >
            <div class="flex flex-col mr-3">
              <p
                data-aos="fade-up"
                data-aos-delay="400"
                class="text-white font-weight-600 text-6 text-left"
              >
                LIVE IN UPTOWN
              </p>
              <p
                data-aos="fade-up"
                data-aos-delay="500"
                class="text-third-100 font-weight-900 text-6.5 text-left"
                style="letter-spacing: 0.15em"
              >
                都心願景 向著你
              </p>
            </div>
          </div>
          <div
            data-aos="fade-up"
            data-aos-delay="600"
            class="text-white font-weight-500 text-4 text-left tracking-widest leading-loose"
            style="margin-top: 5%; margin-left: 6%"
          >
            <p>
              新北第二行政園區近兩萬坪建設預計在2026年完工帶來公部門與東森影視園區就業發展，加上新北科技園區千億產值，全臺白領菁英同步匯聚於此
            </p>
          </div>
        </div>
      </div>
      <div class="h-1/2">
        <div>
          <swiper
            data-aos="fade"
            data-aos-delay="400"
            :options="swiperOptions"
            ref="swiperSection5"
            @slideChangeTransitionStart="slidechange"
          >
            <swiper-slide
              class="slide"
              v-for="(slide, i) in slides"
              v-bind:key="i"
              v-bind:style="{
                backgroundImage: `url(${slide.img})`,
              }"
            >
              <div class="caption">
                {{ slide.caption }}
              </div>
            </swiper-slide>
          </swiper>

          <div class="flex justify-between mt-5 px-8" style="width: 100vw">
            <img
              @click="prev()"
              style="width: 8vw"
              class="w-12 cursor-pointer"
              src="./s4/pc_arrow_l.svg"
              alt=""
            />
            <div class="swiper-pagination" slot="pagination"></div>
            <img
              @click="next()"
              style="width: 8vw"
              class="w-12 cursor-pointer"
              src="./s4/pc_arrow_r.svg"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import '@/assets/style/function.scss';

/* 螢幕尺寸標準 */
.section5{
  padding: 0 0 8vw;
  position: relative;
}

.bg{position: absolute; top: 0;right:size(0);opacity:1;width:size(1652);}
.bg-s5-left ,.bg-s5-right{z-index: 2;}
/* .bg-s5-left {
  background-image: url('./s5/pc_style_bg1.jpg');
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: 100% 0%;
}
.bg-s5-right {
  background-image: url('./s5/pc_style_bg2.jpg');
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: 100% 0;
} */
.swiper-container {
    height:calc(580 * 100vw / 1920);
  width: 39vw;
  margin: 0;
  .slide {
    height: 100%;
    width: 100%;
    background-size: cover;
    .caption {
      position: absolute;
      z-index: 1;
      color: #fff;
      right: 2vw;
      bottom: 0.8vw;
      font-size: 1.2vw;
      font-weight: bold;
      text-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    }
  }
  .swiper-button-prev {
    // left: size(500);
    left: 0;
    bottom: 0;
    width: size(80);
    height: size(80);
    color: #fff;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left;
    // background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E") !important;

    &::after {
      content: '';
    }
  }
  .swiper-button-next {
    right: 0;
    color: #fff;
    width: size(80);
    height: size(80);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right;
    // background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E") !important;

    &::after {
      content: '';
    }
  }
}
::v-deep .swiper-pagination {
  position: relative;
  .swiper-pagination-bullet {
    margin: 0 10px;
    border-radius: 50%;
    background: transparent;
    border: 1px solid white;
    width: size(9);
    height: size(9);
    opacity: 1;
    &.swiper-pagination-bullet-active {
      background: white;
    }
  }
}

// Mobile
.mb-bg-s5 {
  background-image: url('./s5/pc_style_bg1.jpg');
  background-repeat: no-repeat;
  background-size: 88%;
  background-position: 2.5vh 0vh;
}
/* 平板尺寸 */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
}

/* 手機尺寸 */
@media only screen and (max-width: 767px) {
.section5{
  padding: 0 0 8vw;
}
  .swiper-container {
    height:calc(247 * 100vw / 375);
    width: 100vw;
    margin: 0;
    .slide {
      height: 100%;
      width: 100%;
      background-size: cover;
      .caption {
        position: absolute;
        z-index: 1;
        color: #fff;
        right: 1vw;
        bottom: 0.8vw;
        font-size: 3.2vw;
        font-weight: bold;
        text-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
      }
    }
    .swiper-button-prev {
      // left: size(500);
      left: 0;
      bottom: 0;
      width: size(80);
      height: size(80);
      color: #fff;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: left;
      // background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E") !important;

      &::after {
        content: '';
      }
    }
    .swiper-button-next {
      right: 0;
      color: #fff;
      width: size(80);
      height: size(80);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: right;
      // background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E") !important;

      &::after {
        content: '';
      }
    }
  }
  ::v-deep .swiper-pagination {
    position: relative;
    .swiper-pagination-bullet {
      margin: 0 10px;
      border-radius: 50%;
      background: transparent;
      border: 1px solid white;
      width: size(28);
      height: size(28);
      opacity: 1;
      &.swiper-pagination-bullet-active {
        background: white;
      }
    }
  }
}

// 避免內容電腦過渡平板時，設計未考量的調整
@media only screen and (min-width: 1025px) and (max-width: 1199.98px) {
}

// 避免過度到 1280 x 720 時，設計未考量的調整
@media only screen and (min-width: 1025px) and (max-width: 1280px) {
}
</style>

<script>
// @ is an alias to /src
import { isMobile } from '@/utils'
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  name: 'section5',

  components: {
    Swiper,
    SwiperSlide,
  },
  directives: {
    swiper: directive,
  },
  data() {
    return {
      isMobile,
      activeSlide: {},
      title: '／亞東生活圈 都心璀璨一等地／',
      content: `近亞東醫院站、湳雅夜市、台65線<br/>遠東科技園區 Google、亞馬遜國際企業插旗<br/>更近板泰輕軌預劃路線 連袂鼎起最耀眼將來`,
      slides: [
        {
          img: require('@/projects/ryc/s5/pc_slider_1.jpg'),
          caption: '新北科技園區',
        },
        {
          img: require('@/projects/ryc/s5/pc_slider_2.jpg'),
          caption: '湯城廣場',
        },
        {
          img: require('@/projects/ryc/s5/pc_slider_3.jpg'),
          caption: '新北第二行政中心基地',
        },
      ],
      swiperOptions: {
        effect: 'fade',
        speed: 1200,
        spaceBetween: 30,
        loop: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        pagination: {
          el: '.section5 .swiper-pagination',
          clickable: true,
        },
        autoplay: {
          delay: 4500,
          disableOnInteraction: false,
        },
      },
    }
  },

  methods: {
    slidechange() {
      const activeIndex = this.$refs.swiperSection5.$swiper.realIndex
      this.activeSlide = this.slides[activeIndex]
    },
    prev() {
      this.$refs.swiperSection5.$swiper.slidePrev()
    },
    next() {
      this.$refs.swiperSection5.$swiper.slideNext()
    },
  },

  mounted() {
    this.activeSlide = this.slides[0]
  },

  created() {},
}
</script>
