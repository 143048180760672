export default {
  address: '接待中心：新北市三重區光復路一段61巷31弄1號',
  googleSrc:
    'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3614.5568395537944!2d121.47241421500665!3d25.049109183965324!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3442a9148c30a1c7%3A0x8ff221ac6118410c!2z552_5pqY5rKBIOaOpeW-heS4reW_gw!5e0!3m2!1szh-TW!2stw!4v1648088491123!5m2!1szh-TW!2stw',
  googleLink: 'https://goo.gl/maps/6eXoDJfFRar8jnC59',
  phone: '02-2278-2685',
  fbLink: 'https://www.facebook.com/100493952416704/',
  fbMessage: 'https://m.me/100493952416704',
  caseName: '睿暘沁',
  indigatorLength: 10,
  houseInfos: [
    ['產品規劃 /', '一樓庭院宅 42~66坪<br/>一樓金店面 38、48坪'],
    ['投資建設 / ', '日勝生活科技股份有限公司'],
    ['規劃戶數 / ', '住宅58戶、店面26戶'],
    ['建築代銷 / ', '新聯陽機構˙新聯祥廣告'],
    ['經紀人證號 / ', '張展榮(110)雲縣地字000277號'],
    [
      '建照號碼 / ',
      '110板建字第00300號<br/>110板建字第00301號<br/>110板建字第00302號'
    ]
  ],

  gtmCode: ['WWDKCRV'], // 可放置多個
  recaptcha_site_key_v2: '6Lep-78UAAAAAMaZLtddpvpixEb8cqu7v7758gLz',
  recaptcha_site_key: '6Lck-L8UAAAAABxfvNA1NJuBrdl3iLFc3GkudC8s', // recaptcha v3
  recaptcha_user_token: '6Lck-L8UAAAAAIcvenwGDl8_Q1tKbrEYsKuriePa',
  order: {
    title: '預約賞屋',
    subTitle: '東區核心重逢太平洋精品之作<br>不必晝想夜夢 只須及早把握'
  }
}
