<template>
  <div id="section9" class="section9 bg-secondary-100">
    <div
      data-aos="fade"
      data-aos-delay="300"
      v-if="!isMobile"
      class="flex justify-center bg-s9 py-16"
    >
      <div style="height: 35vw; width: 35vw; margin-left: 16.5%">
        <p
          data-aos="fade-up"
          data-aos-delay="400"
          class="text-white font-weight-600 text-1.4 text-left"
        >
          MODERN ARCHITECTURE
        </p>
        <p
          data-aos="fade-up"
          data-aos-delay="500"
          class="text-third-100 font-weight-900 text-1.8 text-left"
          style="letter-spacing: 0.2em"
        >
          自然X光感X都會
        </p>
        <p
          data-aos="fade-up"
          data-aos-delay="600"
          class="text-white font-weight-500 text-1.1 text-left tracking-widest leading-loose"
          style="margin-top: 72%; width: 80%"
        >
          光影是建築的靈魂，因此建築師透過細膩的規劃引光入室，讓日光映襯建築輪廓同時採光不受影響，自然光在居住空間裡輕盈地流動，隨時能與光為伍，行光合作用。
        </p>
      </div>
      <div
        data-aos="fade"
        data-aos-delay="400"
        id="swiper_container"
        style="margin-right: 12%"
      >
        <swiper
          :options="swiperOptions"
          ref="swiperSection9"
          @slideChangeTransitionStart="slidechange"
        >
          <swiper-slide
            class="slide"
            v-for="(slide, i) in slides"
            v-bind:key="i"
            v-bind:style="{
              backgroundImage: `url(${slide.img})`,
            }"
          >
            <div class="caption">
              {{ slide.caption }}
            </div>
          </swiper-slide>
        </swiper>
        <div class="flex justify-between mt-5" style="width: 45vw">
          <img
            @click="prev()"
            class="cursor-pointer"
            style="width: 3vw"
            src="./s4/pc_arrow_l.svg"
            alt=""
          />
          <div class="swiper-pagination" slot="pagination"></div>
          <img
            @click="next()"
            style="width: 3vw"
            class="cursor-pointer"
            src="./s4/pc_arrow_r.svg"
            alt=""
          />
        </div>
      </div>
    </div>
    <!-- Mobile -->
    <div v-if="isMobile">
      <div
        data-aos="fade"
        data-aos-delay="300"
        class="flex flex-col mb-bg-s9"
        style="width: 93.7vw; padding-bottom: 10%"
      >
        <div class="flex items-center" style="margin-top: 9%; margin-left: 6%">
          <div class="flex flex-col mr-3">
            <p
              data-aos="fade-up"
              data-aos-delay="400"
              class="text-white font-weight-600 text-6 text-left"
              style="letter-spacing: 0.1em"
            >
              MODERN ARCHITECTURE
            </p>
            <p
              data-aos="fade-up"
              data-aos-delay="500"
              class="text-third-100 font-weight-900 text-6 text-left"
              style="letter-spacing: 0.1em"
            >
              自然X光感X都會
            </p>
          </div>
        </div>
        <div
          data-aos="fade-up"
          data-aos-delay="500"
          class="text-white font-weight-500 text-4 text-left tracking-widest leading-loose"
          style="margin-top: 5%; margin-left: 6%"
        >
          <p>
            光影是建築的靈魂，因此建築師透過細膩的規劃引光入室，讓日光映襯建築輪廓同時採光不受影響，自然光在居住空間裡輕盈地流動，隨時能與光為伍，行光合作用。
          </p>
        </div>
      </div>

      <div id="swiper_container">
        <swiper
          data-aos="fade"
          data-aos-delay="400"
          :options="swiperOptions"
          ref="swiperSection9"
          @slideChangeTransitionStart="slidechange"
        >
          <swiper-slide
            class="slide"
            v-for="(slide, i) in slides"
            v-bind:key="i"
            v-bind:style="{
              backgroundImage: `url(${slide.img})`,
            }"
          >
            <div class="caption">
              {{ slide.caption }}
            </div>
          </swiper-slide>
        </swiper>
        <div class="flex justify-between mt-5 px-10" style="width: 100vw">
          <img
            @click="prev()"
            class="cursor-pointer"
            style="width: 10vw"
            src="./s4/pc_arrow_l.svg"
            alt=""
          />
          <div class="swiper-pagination" slot="pagination"></div>
          <img
            @click="next()"
            style="width: 10vw"
            class="cursor-pointer"
            src="./s4/pc_arrow_r.svg"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import '@/assets/style/function.scss';

/* 螢幕尺寸標準 */
// Mobile
.mb-bg-s9 {
  background-image: url('./s9/pc_style_bg.svg');
  background-repeat: no-repeat;
  background-size: 97%;
  background-position: 1vh 10vh;
}
// pc
.bg-s9 {
  background-image: url('./s9/pc_style_bg.svg');
  background-repeat: no-repeat;
  background-size: 40%;
  background-position: 15vw 1.8vw;
}
.swiper-container {
  height: 34vw;
  // height: 100%;
  width: 45vw;
  margin: 0;
  .slide {
    height: 100%;
    width: 100%;
    background-size: cover;
    .caption {
      position: absolute;
      z-index: 1;
      color: #fff;
      right: 2vw;
      bottom: 0.8vw;
      font-size: 1.2vw;
      font-weight: bold;
      text-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    }
  }
  .swiper-button-prev {
    // left: size(500);
    left: 0;
    bottom: 0;
    width: size(80);
    height: size(80);
    color: #fff;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left;
    // background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E") !important;

    &::after {
      content: '';
    }
  }
  .swiper-button-next {
    right: 0;
    color: #fff;
    width: size(80);
    height: size(80);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right;
    // background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E") !important;

    &::after {
      content: '';
    }
  }
}
::v-deep .swiper-pagination {
  position: relative;
  .swiper-pagination-bullet {
    margin: 0 10px;
    border-radius: 50%;
    background: transparent;
    border: 1px solid white;
    width: size(9);
    height: size(9);
    opacity: 1;
    &.swiper-pagination-bullet-active {
      background: white;
    }
  }
}
/* 平板尺寸 */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
}

/* 手機尺寸 */
@media only screen and (max-width: 767px) {
  .swiper-container {
    height: calc(319 * 100vw / 375);
    width: 100vw;
    .slide {
      background-position: top center;
      .caption {
        font-size: 3.2vw;
      }
    }
  }
  ::v-deep .swiper-pagination {
    position: relative;
    .swiper-pagination-bullet {
      margin: 0 10px;
      border-radius: 50%;
      background: transparent;
      border: 1px solid white;
      width: size(30);
      height: size(30);
      opacity: 1;
      &.swiper-pagination-bullet-active {
        background: white;
      }
    }
  }
}

// 避免內容電腦過渡平板時，設計未考量的調整
@media only screen and (min-width: 1025px) and (max-width: 1199.98px) {
}

// 避免過度到 1280 x 720 時，設計未考量的調整
@media only screen and (min-width: 1025px) and (max-width: 1280px) {
}
</style>

<script>
// @ is an alias to /src
import { isMobile } from '@/utils'
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  name: 'section9',

  components: {
    Swiper,
    SwiperSlide,
  },
  directives: {
    swiper: directive,
  },
  data() {
    return {
      isMobile,
      activeSlide: {},
      title: '／亞東生活圈 都心璀璨一等地／',
      content: `近亞東醫院站、湳雅夜市、台65線<br/>遠東科技園區 Google、亞馬遜國際企業插旗<br/>更近板泰輕軌預劃路線 連袂鼎起最耀眼將來`,
      slides: [
        {
          img: require('@/projects/ryc/s9/pc_img.jpg'),
          caption: '建築外觀3D示意圖',
        },
        {
          img: require('@/projects/ryc/s9/pc_img_d.jpg'),
          caption: '建築外觀3D示意圖',
        },
      ],
      swiperOptions: {
        effect: 'fade',
        speed: 1200,
        spaceBetween: 30,
        loop: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        pagination: {
          el: '.section9 .swiper-pagination',
          clickable: true,
        },
        autoplay: {
          delay: 4500,
          disableOnInteraction: false,
        },
      },
    }
  },

  methods: {
    slidechange() {
      const activeIndex = this.$refs.swiperSection9.$swiper.realIndex
      this.activeSlide = this.slides[activeIndex]
    },
    prev() {
      this.$refs.swiperSection9.$swiper.slidePrev()
    },
    next() {
      this.$refs.swiperSection9.$swiper.slideNext()
    },
  },

  mounted() {
    this.activeSlide = this.slides[0]
  },

  created() {},
}
</script>
