var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isMobile)?_c('div',[_c('img',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isShowMenu),expression:"!isShowMenu"}],staticClass:"w-1/10 mobile-menu-icon",attrs:{"src":require("../projects/ryc/s1/mobile_menu.svg"),"alt":""},on:{"click":function($event){return _vm.toggleMenu()}}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowMenu),expression:"isShowMenu"}],staticClass:"w-1/10 mobile-menu-icon",staticStyle:{"z-index":"99999999"},attrs:{"src":require("../projects/ryc/s1/mobile_close.svg"),"alt":""},on:{"click":function($event){return _vm.toggleMenu()}}}),_c('ul',{staticClass:"mobile-menu bg-white opacity-90 fixed top-0 bottom-0 left-0 right-0 font-weight-900 text-lg",class:_vm.isShowMenu ? 'active_menu-in' : 'active_menu-out',staticStyle:{"padding-top":"65%"},on:{"click":function($event){return _vm.toggleMenu()}}},[_c('li',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
        element: "#section2",
        offset: 0,
        onDone: _vm.onDoneScrollTo,
      }),expression:"{\n        element: `#section2`,\n        offset: 0,\n        onDone: onDoneScrollTo,\n      }"}],staticClass:"mx-10 pb-3 mb-3",staticStyle:{"border-bottom":"1px solid#676767"}},[_vm._v("\n      美好入心\n    ")]),_c('li',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
        element: "#section4",
        offset: 0,
        onDone: _vm.onDoneScrollTo,
      }),expression:"{\n        element: `#section4`,\n        offset: 0,\n        onDone: onDoneScrollTo,\n      }"}],staticClass:"mx-10 pb-3 mb-3",staticStyle:{"border-bottom":"1px solid#676767"}},[_vm._v("\n      水岸富翁\n    ")]),_c('li',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
        element: "#section6",
        offset: -0,
        onDone: _vm.onDoneScrollTo,
      }),expression:"{\n        element: `#section6`,\n        offset: -0,\n        onDone: onDoneScrollTo,\n      }"}],staticClass:"mx-10 pb-3 mb-3",staticStyle:{"border-bottom":"1px solid#676767"}},[_vm._v("\n      花樣生活\n    ")]),_c('li',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
        element: "#section8",
        offset: -10,
        onDone: _vm.onDoneScrollTo,
      }),expression:"{\n        element: `#section8`,\n        offset: -10,\n        onDone: onDoneScrollTo,\n      }"}],staticClass:"mx-10 pb-3 mb-3",staticStyle:{"border-bottom":"1px solid#676767"}},[_vm._v("\n      捷運熱點\n    ")]),_c('li',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
        element: "#section9",
        offset: 0,
        onDone: _vm.onDoneScrollTo,
      }),expression:"{\n        element: `#section9`,\n        offset: 0,\n        onDone: onDoneScrollTo,\n      }"}],staticClass:"mx-10 pb-3 mb-3"},[_vm._v("\n      空間自由\n    ")])]),_c('div',{class:{ 'mobile-nav': true }},[_c('a',{staticClass:"nav-item",on:{"click":_vm.showCallDialog}},[_c('font-awesome-icon',{attrs:{"icon":"phone-alt"}}),_c('div',{staticClass:"label"},[_vm._v("撥打電話")])],1),_c('a',{staticClass:"nav-item",on:{"click":_vm.showMessengerDialog}},[_c('font-awesome-icon',{attrs:{"icon":['fab', 'facebook-messenger']}}),_c('div',{staticClass:"label"},[_vm._v("FB諮詢")])],1),_c('a',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({ element: "#contact", offset: 0 }),expression:"{ element: `#contact`, offset: 0 }"}],staticClass:"nav-item bt_registration"},[_c('font-awesome-icon',{attrs:{"icon":"home"}}),_c('div',{staticClass:"label"},[_vm._v("預約賞屋")])],1),_c('a',{staticClass:"nav-item",on:{"click":_vm.showMapDialog}},[_c('font-awesome-icon',{attrs:{"icon":"map-marker-alt"}}),_c('div',{staticClass:"label"},[_vm._v("地圖導航")])],1),_c('el-dialog',{attrs:{"title":"","visible":_vm.isShowCallDialog,"width":"90%","modal-append-to-body":false},on:{"update:visible":function($event){_vm.isShowCallDialog=$event}}},[_c('CallDialog',{attrs:{"phone":_vm.info.phone}})],1),_c('el-dialog',{attrs:{"title":"","visible":_vm.isShowMessengerDialog,"width":"90%","modal-append-to-body":false},on:{"update:visible":function($event){_vm.isShowMessengerDialog=$event}}},[_c('MessengerDialog',{attrs:{"messenger":_vm.info.fbMessage}})],1),_c('el-dialog',{attrs:{"title":"","visible":_vm.isShowMapDialog,"width":"90%","modal-append-to-body":false},on:{"update:visible":function($event){_vm.isShowMapDialog=$event}}},[_c('MapDialog',{attrs:{"link":_vm.info.googleLink,"address":_vm.info.address}})],1)],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }