<template>
  <div v-if="!isMobile" id="pc_nav" class="flex justify-center">
    <div class="w-4/5 flex justify-around">
      <div class="flex">
        <div
          :class="
            navItemHover2 === true || navItemActive === 'section2'
              ? 'opacity-100'
              : 'opacity-0'
          "
          class="navItemLine self-center opacity-0"
        ></div>
        <a
          href=""
          v-scroll-to="{ element: `#section2`, offset: -70 }"
          class="self-center font-weight-700 mx-3 hover:text-primary-100"
          :class="navItemActive === 'section2' ? 'text-primary-100' : ''"
          @mouseenter="navItemHover2 = true"
          @mouseleave="navItemHover2 = false"
          >美好入心
        </a>
        <div
          :class="
            navItemHover2 === true || navItemActive === 'section2'
              ? 'opacity-100'
              : 'opacity-0'
          "
          class="navItemLine self-center opacity-0"
        ></div>
      </div>
      <!--  -->
      <div class="flex">
        <div
          :class="
            navItemHover4 === true || navItemActive === 'section4'
              ? 'opacity-100'
              : 'opacity-0'
          "
          class="navItemLine self-center opacity-0"
        ></div>
        <a
          href=""
          v-scroll-to="{ element: `#section4`, offset: -70 }"
          class="self-center font-weight-700 mx-3 hover:text-primary-100"
          :class="navItemActive === 'section4' ? 'text-primary-100' : ''"
          @mouseenter="navItemHover4 = true"
          @mouseleave="navItemHover4 = false"
          >水岸富翁
        </a>
        <div
          :class="
            navItemHover4 === true || navItemActive === 'section4'
              ? 'opacity-100'
              : 'opacity-0'
          "
          class="navItemLine self-center opacity-0"
        ></div>
      </div>
      <!--  -->
      <!-- <a
        href=""
        v-scroll-to="{ element: `#section4`, offset: -100 }"
        class="self-center font-weight-700"
        :class="navItemActive === 'section4' ? 'navItem text-primary-100' : ''"
        >水岸富翁</a
      > -->
      <!--  -->
      <div class="flex">
        <div
          :class="
            navItemHover6 === true || navItemActive === 'section6'
              ? 'opacity-100'
              : 'opacity-0'
          "
          class="navItemLine self-center opacity-0"
        ></div>
        <a
          href=""
          v-scroll-to="{ element: `#section6`, offset: -100 }"
          class="self-center font-weight-700 mx-3 hover:text-primary-100"
          :class="navItemActive === 'section6' ? 'text-primary-100' : ''"
          @mouseenter="navItemHover6 = true"
          @mouseleave="navItemHover6 = false"
          >花樣生活
        </a>
        <div
          :class="
            navItemHover6 === true || navItemActive === 'section6'
              ? 'opacity-100'
              : 'opacity-0'
          "
          class="navItemLine self-center opacity-0"
        ></div>
      </div>
      <!--  -->
      <!-- <a
        href=""
        v-scroll-to="{ element: `#section6`, offset: -100 }"
        class="self-center font-weight-700"
        :class="navItemActive === 'section6' ? 'navItem text-primary-100' : ''"
        >花樣生活</a
      > -->
      <!--  -->
      <div class="flex">
        <div
          :class="
            navItemHover8 === true || navItemActive === 'section8'
              ? 'opacity-100'
              : 'opacity-0'
          "
          class="navItemLine self-center opacity-0"
        ></div>
        <a
          href=""
          v-scroll-to="{ element: `#section8`, offset: -100 }"
          class="self-center font-weight-700 mx-3 hover:text-primary-100"
          :class="navItemActive === 'section8' ? 'text-primary-100' : ''"
          @mouseenter="navItemHover8 = true"
          @mouseleave="navItemHover8 = false"
          >捷運熱點
        </a>
        <div
          :class="
            navItemHover8 === true || navItemActive === 'section8'
              ? 'opacity-100'
              : 'opacity-0'
          "
          class="navItemLine self-center opacity-0"
        ></div>
      </div>
      <!--  -->
      <!-- <a
        href=""
        v-scroll-to="{ element: `#section8`, offset: -100 }"
        class="self-center font-weight-700"
        :class="navItemActive === 'section8' ? 'navItem text-primary-100' : ''"
        >捷運熱點</a
      > -->
      <!--  -->

      <div class="flex">
        <div
          :class="
            navItemHover9 === true || navItemActive === 'section9'
              ? 'opacity-100'
              : 'opacity-0'
          "
          class="navItemLine self-center opacity-0"
        ></div>
        <a
          href=""
          v-scroll-to="{ element: `#section9`, offset: -100 }"
          class="self-center font-weight-700 mx-3 hover:text-primary-100"
          :class="navItemActive === 'section9' ? 'text-primary-100' : ''"
          @mouseenter="navItemHover9 = true"
          @mouseleave="navItemHover9 = false"
          >空間自由
        </a>
        <div
          :class="
            navItemHover9 === true || navItemActive === 'section9'
              ? 'opacity-100'
              : 'opacity-0'
          "
          class="navItemLine self-center opacity-0"
        ></div>
      </div>
      <!--  -->
      <!-- <a
        href=""
        v-scroll-to="{ element: `#section9`, offset: -100 }"
        class="self-center font-weight-700"
        :class="navItemActive === 'section9' ? 'navItem text-primary-100' : ''"
        >空間自由</a
      > -->
    </div>
  </div>
</template>

<script>
import { isMobile, isTablet } from '@/utils'

export default {
  name: 'PCNav',
  props: {
    navItemActive: {
      default: undefined,
      type: String,
    },
  },
  data() {
    return {
      isMobile,
      isTablet,
      navItemHover2: false,
      navItemHover4: false,
      navItemHover6: false,
      navItemHover8: false,
      navItemHover9: false,
    }
  },
  watch: {
    navItemActive() {
      if (this.navItemActive === 'section2') {
        this.initNavItemHover()
        this.navItemHover2 = true
      }
      if (this.navItemActive === 'section4') {
        this.initNavItemHover()
        this.navItemHover4 = true
      }
      if (this.navItemActive === 'section6') {
        this.initNavItemHover()
        this.navItemHover6 = true
      }
      if (this.navItemActive === 'section8') {
        this.initNavItemHover()
        this.navItemHover8 = true
      }
      if (this.navItemActive === 'section9') {
        this.initNavItemHover()
        this.navItemHover9 = true
      }
    },
  },
  computed: {
    offset() {
      if (this.isTablet) {
        return 0
      }

      return 0
    },
  },
  methods: {
    initNavItemHover() {
      this.navItemHover2 = false
      this.navItemHover4 = false
      this.navItemHover6 = false
      this.navItemHover8 = false
      this.navItemHover9 = false
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/variableColor.scss';
.navItemLine {
  background-color: #0086cc;
  height: 2px;
  width: 20px;
  transition: all 0.3s;
}
#pc_nav {
  background-color: white;
  opacity: 0.8;
  // font-size: 28px;
  font-size: 1.3vw;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  height: 70px;
}
// .navItem {
//   position: relative;
// }
// .navItem::before {
//   content: '';
//   position: absolute;
//   top: 50%;
//   left: -35%;
//   width: 20px;
//   height: 2px;
//   background: #0086cc;
//   opacity: 1;
// }
// .navItem::after {
//   content: '';
//   position: absolute;
//   top: 50%;
//   right: -35%;
//   width: 20px;
//   height: 2px;
//   background: #0086cc;
//   opacity: 1;
// }

/* 手機尺寸 */
@media only screen and (max-width: 767px) {
}
</style>
