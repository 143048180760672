<template>
  <div class="section3 bg-secondary-100">
    <div v-if="!isMobile" class="flex justify-between h-screen w-full pt-4">
      <div class="w-1/2">
        <div
          data-aos="fade"
          data-aos-delay="0" data-aos-duration="5000"
          class="bg-img-left"
          style="margin-left: 17%; "
        >
          <div class="flex justify-between h-3/5">
            <div class="w-1/6 ml-1" style="margin-top: 15%">
              <img
                data-aos="fade"
                data-aos-delay="400"
                src="./s3/pc_style_title.jpg"
                alt=""
              />
            </div>
            <div class="flex-col" style="width: 78%; margin-top: 16%">
              <p
                data-aos="fade-up"
                data-aos-delay="400"
                class="text-white font-weight-600 text-1.4 text-left"
              >
                BUYING A HOME
              </p>
              <p
                data-aos="fade-up"
                data-aos-delay="500"
                class="text-third-100 font-weight-900 text-1.8 text-left"
                style="letter-spacing: 0.2em"
              >
                微努力，好快樂
              </p>
              <p
                data-aos="fade-up"
                data-aos-delay="600"
                class="text-white font-weight-500 text-1.1 text-left tracking-widest leading-loose"
                style="margin-top: 37%"
              >
                理想生活成為進行式，讓租金灌溉成家夢想，生活不將就而是活成想要的模樣。前進三重新都心獲取百億建設與重劃區加值力，城市菁英私藏的水岸生活從『睿暘沁』起始。
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        data-aos="fade"
        data-aos-delay="400"
        class="w-3/7 relative h-6/10vh"
        style="margin-top: 4%"
      >
        <img class="w-full h-full object-cover" src="./s3/pc_img.jpg" alt="" />
        <span
          class="absolute bottom-2 right-2 text-white z-10 font-weight-700 text-1"
        >
          新北都會公園
        </span>
      </div>
    </div>

    <!-- Mobile -->
    <div v-if="isMobile" class="pb-10">
      <div data-aos="fade" data-aos-delay="300" class="mb-bg-s3">
        <div class="flex flex-col" style="height: 85vw; width: 93.7vw">
          <div
            class="flex items-center"
            style="margin-top: 17%; margin-left: 6%"
          >
            <div class="flex flex-col mr-3">
              <p
                data-aos="fade-up"
                data-aos-delay="400"
                class="text-white font-weight-600 text-6 text-left"
              >
                BUYING A HOME
              </p>
              <p
                data-aos="fade-up"
                data-aos-delay="500"
                class="text-third-100 font-weight-900 text-6.5 text-left"
                style="letter-spacing: 0.15em"
              >
                微努力，好快樂
              </p>
            </div>
            <img
              data-aos="fade"
              data-aos-delay="400"
              src="./s3/pc_style_title.jpg"
              alt=""
              style="width: 18%;"
            />
          </div>
          <p
            data-aos="fade-up"
            data-aos-delay="600"
            class="text-white font-weight-500 text-4 text-left tracking-widest leading-loose"
            style="margin-top: 5%; margin-left: 6%"
          >
            理想生活成為進行式，讓租金灌溉成家夢想，生活不將就而是活成想要的模樣。前進三重新都心獲取百億建設與重劃區加值力，城市菁英私藏的水岸生活從『睿暘沁』起始。
          </p>
        </div>
      </div>
      <div data-aos="fade" data-aos-delay="400" class="h-1/2 relative">
        <img class="w-full h-full object-cover" src="./s3/pc_img.jpg" alt="" />
        <span
          class="absolute bottom-2 right-2 text-white z-10 font-weight-700"
        >
          新北都會公園
        </span>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
@import '@/assets/style/function.scss';
.bg-img-left {
  background-image: url('./s3/pc_style_bg.png');
  background-repeat: no-repeat;
  background-size: 99%;
}
.bg-img-left{width: size(800);
height: size(800);
}
// Mobile
.mb-bg-s3 {
  background-image: url('./s3/pc_style_bg.png');
  background-repeat: no-repeat;
  background-size: 88%;
  background-position: 2.5vh 6vh;
}
/* 螢幕尺寸標準 */
/* 平板尺寸 */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
}

/* 手機尺寸 */
@media only screen and (max-width: 767px) {
  .bottom-2{
        font-size: 3.2vw;
  }
}

// 避免內容電腦過渡平板時，設計未考量的調整
@media only screen and (min-width: 1025px) and (max-width: 1199.98px) {
}

// 避免過度到 1280 x 720 時，設計未考量的調整
@media only screen and (min-width: 1025px) and (max-width: 1280px) {
}
</style>

<script>
// @ is an alias to /src
import { isMobile } from '@/utils'
import 'swiper/css/swiper.css'

export default {
  name: 'section3',

  components: {},
  directives: {},
  data() {
    return {
      isMobile,
      activeSlide: {},
      slides: [
        {
          img: '',
          content:
            '<div class="title">／3.3萬坪綠境 6座生態跳島／</div><div class="fontSize-1.8vh">社區綠覆率達150% 超3000坪濕地水嶼</div><div class="fontSize-1.8vh">年固碳量3001噸 製氧量相當194頃人工林</div><div class="fontSize-1.8vh">日常生息 身心快適 健康 永遠站在樹這邊</div>',
        },
      ],
      swiperOptions: {
        spaceBetween: 30,
        effect: 'fade',
        speed: 1200,
        loop: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        pagination: {
          el: '.section3 .swiper-pagination',
          clickable: true,
        },
        autoplay: {
          delay: 4500,
          disableOnInteraction: false,
        },
      },
    }
  },

  methods: {
    slidechange() {
      const activeIndex = this.$refs.swiperSection3.$swiper.realIndex
      this.activeSlide = this.slides[activeIndex]

      // setTimeout(() => {
      //   $('.section3 [data-aos]').each((i, val) => {
      //     $(val).addClass('aos-animate')
      //   })
      //   setTimeout(() => {
      //     $('.section3 .hasdivider').addClass('showin')
      //   }, 200)
      //   setTimeout(() => {
      //     $('.section3 .divider').addClass('showin')
      //   }, 400)
      // }, 0)
    },
    prev() {
      console.log('prev')
      this.$refs.swiperSection3.$swiper.slidePrev()
    },
    next() {
      this.$refs.swiperSection3.$swiper.slideNext()
    },
  },

  mounted() {
    this.activeSlide = this.slides[0]
    if (isMobile) {
      this.slides.push({
        img: require('@/projects/rss/s3/slider_3.jpg'),
        content:
          '<div class="title">／3.3萬坪綠境 6座生態跳島／</div><div class="fontSize-1.8vh">社區綠覆率達150% 超3000坪濕地水嶼</div><div class="fontSize-1.8vh">年固碳量3001噸 製氧量相當194頃人工林</div><div class="fontSize-1.8vh">日常生息 身心快適 健康 永遠站在樹這邊</div>',
      })
    }
  },

  created() {},
}
</script>
