<template>
  <div class="section10 bg-primary-100">
    <div v-if="!isMobile" style="height: 55vw">
      <div class="mb-16 flex-col mx-auto">
        <p
          data-aos="fade-up"
          data-aos-delay="400"
          class="text-white font-weight-600 text-1.4 text-left"
          style="margin-left: 48%"
        >
          THE DEFINING A STYLE
        </p>
        <p
          data-aos="fade-up"
          data-aos-delay="500"
          class="text-third-100 font-weight-900 text-1.8 text-left"
          style="letter-spacing: 0.2em; margin-left: 48%"
        >
          現代X簡單X優雅
        </p>

        <div v-for="(slide, i) in slides" :key="i">
          <p
            v-if="currentIndex === i"
            data-aos="fade-in"
            class="text-white font-weight-500 text-1.1 text-center tracking-widest leading-loose mx-auto"
            style="width: 31%"
          >
            {{ slide.description }}
          </p>
        </div>
      </div>
      <swiper
        class="swiper"
        ref="swiperSection10"
        @slideChangeTransitionStart="slidechange"
        @slideChange="changeSwiperIndex"
        :options="swiperOption"
      >
        <swiper-slide
          class="slide"
          v-for="(slide, i) in slides"
          v-bind:key="i"
          v-bind:style="{
            backgroundImage: `url(${slide.img})`,
          }"
        >
          <div class="caption">
            {{ slide.caption }}
          </div>
        </swiper-slide>
      </swiper>
      <div
        class="flex justify-between mt-5"
        style="width: 100vw; padding: 0 35%"
      >
        <img
          @click="prev()"
          class="cursor-pointer"
          style="width: 3vw"
          src="./s10/pc_arrow_l.svg"
          alt=""
        />
        <div class="swiper-pagination" slot="pagination"></div>
        <img
          @click="next()"
          style="width: 3vw"
          class="cursor-pointer"
          src="./s10/pc_arrow_r.svg"
          alt=""
        />
      </div>
    </div>

    <!-- Mobile -->
    <div v-if="isMobile" class="pb-6">
      <div class="flex flex-col pb-5" style="width: 93.7vw">
        <div
          class="flex items-center"
          style="margin-top: 0.7%; margin-left: 6%"
        >
          <div class="flex flex-col mr-3">
            <p
              data-aos="fade-up"
              data-aos-delay="400"
              class="text-white font-weight-900 text-6.5 text-left"
            >
              THE DEFINING A STYLE
            </p>
            <p
              data-aos="fade-up"
              data-aos-delay="500"
              class="text-third-100 font-weight-900 text-6.5 text-left"
              style="letter-spacing: 0.1em"
            >
              現代X簡單X優雅
            </p>
          </div>
        </div>


        <div v-for="(slide, i) in slides" :key="i">
          <p data-aos="fade-in" 
          v-if="currentIndex === i"
          class="text-white font-weight-500 text-4 text-left tracking-widest leading-loose"
          style="margin-top: 5%; margin-left: 6%"
          >
            
            {{ slide.description }}
          </p>
        </div>
      </div>

      <div data-aos="fade" data-aos-delay="400">
        <swiper
          :options="swiperOption"
          ref="swiperSection10"
          @slideChangeTransitionStart="slidechange"
          @slideChange="changeSwiperIndex"
        >
          <swiper-slide
            class="slide"
            v-for="(slide, i) in slides"
            v-bind:key="i"
            v-bind:style="{
              backgroundImage: `url(${slide.img})`,
            }"
          >
            <div class="caption">
              {{ slide.caption }}
            </div>
          </swiper-slide>
        </swiper>

        <div
          class="flex justify-between mt-5"
          style="width: 100vw; padding: 0 10%"
        >
          <img
            @click="prev()"
            class="cursor-pointer"
            style="width: 10vw"
            src="./s10/pc_arrow_l.svg"
            alt=""
          />
          <div class="swiper-pagination" slot="pagination"></div>
          <img
            @click="next()"
            style="width: 10vw"
            class="cursor-pointer"
            src="./s10/pc_arrow_r.svg"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import { isMobile } from '@/utils'

export default {
  name: 'swiper-example-centered-slides',
  title: 'Centered slides',
  components: {
    Swiper,
    SwiperSlide,
  },
  mounted() {
    this.activeSlide = this.slides[0]
  },
  data() {
    return {
      currentIndex: 1,
      isMobile,
      slides: [
        {
          img: require('@/projects/ryc/s10/pc_slider_3.jpg'),
          caption: '迎賓大廳',
          description:
            '透過視覺留白，鋪陳出春季獨有的清新與柔和，陽光緩緩地撒入溫暖整個空間，讓每位進門的人都能感受到如沐春風般的愜意。',
        },
        {
          img: require('@/projects/ryc/s10/pc_slider_4.jpg'),
          caption: '智能櫃＆信箱',
          description:
            '透過視覺留白，鋪陳出春季獨有的清新與柔和，陽光緩緩地撒入溫暖整個空間，讓每位進門的人都能感受到如沐春風般的愜意。',
        },
        {
          img: require('@/projects/ryc/s10/pc_slider_2.jpg'),
          caption: '恆美迴廊',
          description:
            '屬於秋日的沉靜澄明在此展開，不管是獨處沉澱、或與親友交換故事，皆能感受空間帶來的雋永況味。',
        },
        {
          img: require('@/projects/ryc/s10/pc_slider_1.jpg'),
          caption: '交誼廳',
          description:
            '讓日光盡情渲染，空間充滿夏日的活潑感。望向窗外的綠意、看樹葉與太陽玩躲貓貓，這一切平靜又美好。',
        },
        {
          img: require('@/projects/ryc/s10/pc_slider_5.jpg'),
          caption: '閱覽區',
          description:
            '冬季的寂靜感最適合拿來閱讀，在閱覽室裡感受書頁的觸感、吸收字裡行間的文采，讓靈魂在書香中薰陶。',
        },
      ],
      swiperOption: {
        slidesPerView: isMobile ? 1 : 2,
        spaceBetween: 30,
        centeredSlides: true,
        // effect: 'fade',
        // speed: 1200,
        loop: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        pagination: {
          el: '.section10 .swiper-pagination',
          clickable: true,
        },
        autoplay: {
          delay: 4500,
          disableOnInteraction: false,
        },
      },
    }
  },
  methods: {
    slidechange() {
      const activeIndex = this.$refs.swiperSection10.$swiper.realIndex
      this.activeSlide = this.slides[activeIndex]
    },
    prev() {
      this.$refs.swiperSection10.$swiper.slidePrev()
    },
    next() {
      this.$refs.swiperSection10.$swiper.slideNext()
    },
    changeSwiperIndex() {
      this.currentIndex = this.$refs.swiperSection10.$swiper.realIndex
    },
  },
}
</script>

<style lang="scss" scoped>
.swiper-slide {
  height: 30vw;
  background-repeat: no-repeat;
}

.caption {
  position: absolute;
  z-index: 1;
  color: #fff;
  right: 2vw;
  bottom: 0.8vw;
  font-size: 1.2vw;
  font-weight: bold;
  text-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}

::v-deep .swiper-pagination {
  position: relative;
  .swiper-pagination-bullet {
    margin: 0 10px;
    border-radius: 50%;
    background: transparent;
    border: 1px solid white;
    width: size(9);
    height: size(9);
    opacity: 1;
    &.swiper-pagination-bullet-active {
      background: white;
    }
  }
}
@media only screen and (max-width: 767px) {
  .section10 {
    padding-top: 13vw;
  }
  .swiper-container {
    height: calc(208 * 100vw / 375);
    width: 100vw;
    margin: 0;
    .slide {
      height: 100%;
      width: 100%;
      background-size: cover;
      .caption {
        position: absolute;
        z-index: 1;
        color: #fff;
        right: 1vw;
        bottom: 0.8vw;
        font-size: 3.2vw;
        font-weight: bold;
        text-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
      }
    }
    .swiper-button-prev {
      // left: size(500);
      left: 0;
      bottom: 0;
      width: size(80);
      height: size(80);
      color: #fff;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: left;
      // background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E") !important;

      &::after {
        content: '';
      }
    }
    .swiper-button-next {
      right: 0;
      color: #fff;
      width: size(80);
      height: size(80);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: right;
      // background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E") !important;

      &::after {
        content: '';
      }
    }
  }
  ::v-deep .swiper-pagination {
    position: relative;
    .swiper-pagination-bullet {
      margin: 0 10px;
      border-radius: 50%;
      background: transparent;
      border: 1px solid white;
      width: size(28);
      height: size(28);
      opacity: 1;
      &.swiper-pagination-bullet-active {
        background: white;
      }
    }
  }
}
</style>
