<template>
  <div class="home no-padding-top relative overflow-x-auto">
    <div id="locomotive" class="overflow-x-auto">
      <PCNav :nav-item-active="navItemActive" />
      <Section1 class="section" data-scroll />
      <Section2 v-view="viewHandler2" class="section" data-scroll />
      <Section3 class="section" data-scroll />
      <Section4 v-view="viewHandler4" class="section" data-scroll />
      <Section5 class="section" data-scroll />
      <Section6
        v-view="viewHandler6"
        @openBigImg="bigImgMode = true"
        class="section"
        data-scroll
      />
      <Section7 class="section" data-scroll />
      <Section8 v-view="viewHandler8" class="section" data-scroll />
      <Section9 v-view="viewHandler9" data-scroll />
      <Section10 class="section" data-scroll />
      <ContactSection class="section" data-scroll />
    </div>
    <Loading :loading="load" data-scroll />
    <MobileNav />
    <!-- Big Image -->
    <div class="dialog" v-if="bigImgMode">
      <div class="dialog-bg">
        <div class="dialog-close flex-c" @click="bigImgMode = false">
          <img class="w-6" src="../projects/ryc/s6/mb_close.svg" alt />
        </div>
        <img src="../projects/ryc/s6/pc_img.jpg" alt class="dialog-img" />
      </div>
    </div>
    <!--  -->
    <el-dialog
      title
      :visible="isShowCallDialog"
      :width="isMobile ? '90%' : '500px'"
      :modal-append-to-body="false"
      @close="closeDialog()"
    >
      <CallDialog :phone="info.phone" />
    </el-dialog>
    <!--  -->
    <el-dialog
      title
      :visible="isShowMessengerDialog"
      :width="isMobile ? '90%' : '500px'"
      :modal-append-to-body="false"
      @close="closeDialog()"
    >
      <MessengerDialog :messenger="info.fbMessage" />
    </el-dialog>
    <!--  -->
    <el-dialog
      title
      :visible="isShowMapDialog"
      :width="isMobile ? '90%' : '500px'"
      :modal-append-to-body="false"
      @close="closeDialog()"
    >
      <MapDialog :link="info.googleLink" :address="info.address" />
    </el-dialog>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from 'vuex'
import info from '@/info'
import CallDialog from '@/components/Dialog/Call'
import MessengerDialog from '@/components/Dialog/Messenger'
import MapDialog from '@/components/Dialog/Map'
import $ from 'jquery'
import { isMobile } from '@/utils'
import ContactSection from '@/layouts/ContactSection.vue'
import MobileNav from '@/layouts/MobileNav.vue'
import PCNav from '@/layouts/PCNav.vue'
import Loading from '@/components/Loading.vue'
import AOS from '@/lib/aos/src/js/aos'

import Section1 from '@/projects/ryc/section1.vue'
import Section2 from '@/projects/ryc/section2.vue'
import Section3 from '@/projects/ryc/section3.vue'
import Section4 from '@/projects/ryc/section4.vue'
import Section5 from '@/projects/ryc/section5.vue'
import Section6 from '@/projects/ryc/section6.vue'
import Section7 from '@/projects/ryc/section7.vue'
import Section8 from '@/projects/ryc/section8.vue'
import Section9 from '@/projects/ryc/section9.vue'
import Section10 from '@/projects/ryc/section10.vue'
import LocomotiveScroll from 'locomotive-scroll'

export default {
  name: 'home',
  components: {
    Loading,
    CallDialog,
    MessengerDialog,
    MapDialog,
    ContactSection,
    MobileNav,
    PCNav,
    Section1,
    Section2,
    Section3,
    Section4,
    Section5,
    Section6,
    Section7,
    Section8,
    Section9,
    Section10,
  },

  data() {
    return {
      info,
      isMobile,
      isSide: false,
      load: true,
      bigImgMode: false,
      navItemActive: '',
    }
  },
  computed: {
    ...mapState([
      'isShowCallDialog',
      'isShowMessengerDialog',
      'isShowMapDialog',
    ]),
  },
  created() {
    $(document).ready(() => {
      var imagesLoaded = 0
      var totalImages = $('img').length

      const allImagesLoaded = () => {
        this.load = false
      }
      const imageLoaded = () => {
        imagesLoaded++

        if (imagesLoaded > totalImages * 0.5) {
          allImagesLoaded()
        }
      }
      $('img').each(function (idx, img) {
        $('<img>').on('load', imageLoaded).attr('src', $(img).attr('src'))
      })
    })
  },
  mounted() {
    AOS.init({
      duration: 1000,
    })

    this.locomotive = new LocomotiveScroll({
      el: document.querySelector('#locomotive'),
      smooth: false,
      repeat: true,
      offset: ['45%', '45%'],
      tablet: {
        smooth: false,
        breakpoint: 250,
      },
      smartphone: {
        smooth: false,
      },
      lerp: 0.05,
    })

    this.locomotive.on('scroll', obj => {
      $('.is-inview [data-aos]').addClass('aos-animate')
      setTimeout(() => {
        $('.is-inview .divider').removeClass('showin').addClass('showin')
        $('.is-inview .hasdivider').removeClass('showin').addClass('showin')
      }, 2000)
    })

    // let imgs = document.images;

    // [].forEach.call(imgs, function (img) {
    //   if (img.complete) update_();
    //   else img.addEventListener("load", update_, false);
    // });

    const self = this

    setInterval(() => {
      update_()
    }, 1000)

    function update_() {
      self.locomotive.update()
    }

    // this.scrolling()
  },
  methods: {
    closeDialog() {
      this.$store.state.isShowCallDialog = false
      this.$store.state.isShowMessengerDialog = false
      this.$store.state.isShowMapDialog = false
    },
    viewHandler2(e) {
      if (e.type === 'progress') this.navItemActive = 'section2'
    },
    viewHandler4(e) {
      if (e.type === 'progress') this.navItemActive = 'section4'
    },
    viewHandler6(e) {
      if (e.type === 'progress') this.navItemActive = 'section6'
    },
    viewHandler8(e) {
      if (e.type === 'progress') this.navItemActive = 'section8'
    },
    viewHandler9(e) {
      if (e.type === 'progress') this.navItemActive = 'section9'
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../assets/style/variableColor.scss';
@import '../assets/style/function.scss';
img {
  max-width: 600vw;
}
.dialog {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.34);
  z-index: 999999999999999999999999999;
  overflow-y: scroll;

  .dialog-bg {
    width: 100%;
    height: 100%;
    // padding-top: $nav_pc_height;
    background-attachment: fixed;
    background-size: auto;
    background-position: center center;
    background-repeat: repeat;
  }

  .dialog-img {
    position: absolute;
    width: auto;
    height: 100vh;
    top: 0;
    left: 0;
  }

  .dialog-close {
    @include div_r_m(31, 32, 10, 10);
    // border: 1px solid #fff;
    position: fixed;
    cursor: pointer;
    background-color: rgba(54, 54, 54, 0.8);
    z-index: 2;

    img {
      width: 90%;
    }
  }

  .dialog-hand {
    @include img_l_m(80, 334, 148);
    opacity: 0;
    transition: opacity 0.5s;
    &.active {
      opacity: 1;
    }
    animation: swing 1.5s ease-in-out 0s infinite alternate-reverse;
  }

  @keyframes swing {
    to {
      transform: translateX(-25%);
    }
  }
}
.home {
  &::before {
    content: '';
    position: fixed;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: url('../projects/ryc/pc_bg.jpg') center 0 no-repeat;
    background-size: cover;
  }
  &::-webkit-scrollbar {
    display: none;
  }
}

.divider {
  transform: scale(0) !important;
  transition: all 1s !important;
  &.showin {
    transform: scale(1) !important;
  }
}

.hasdivider {
  &:after {
    transform: scale(0) !important;
    transition: all 1s !important;
  }

  &.showin {
    &::after {
      transform: scale(1) !important;
    }
  }
}

.section,
.section .fp-slide,
.section .fp-tableCell {
  will-change: transform, opacity;
}

.fp-left {
  margin-left: 12px;
}
//間隔
#fp-nav ul li,
.fp-slidesNav ul li {
  margin: 24px 0 !important;
}

#fp-nav {
  z-index: 9999 !important;
}

//基礎顏色
#fp-nav ul li a span,
.fp-slidesNav ul li a span {
  width: 14px !important;
  height: 14px !important;
  margin: 0 !important;
  background: #fff !important;
  filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.2));
}
//Active顏色
#fp-nav ul li a.active span,
#fp-nav ul li:hover a.active span,
.fp-slidesNav ul li a.active span,
.fp-slidesNav ul li:hover a.active span {
  background: #fff000 !important;
}

@media only screen and (max-width: 767px) {
  .home {
    &::before {
      //background-image: url("~@/projects/llcs/s1/bg_mo.jpg");
    }
  }
  .fp-left {
    display: flex;
    margin: 0 !important;
    left: 50% !important;
    top: 0 !important;
    width: 80vw;
    transform: translate(-53%, 20px) !important;
  }

  #fp-nav ul li,
  .fp-slidesNav ul li {
    margin: 0 !important;
  }

  #fp-nav ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    left: 0;
    justify-content: space-around;
  }
}
</style>
