<template>
  <div id="section2" class="section2">
    <div v-if="!isMobile" style="height: 51vw" class="h-full flex-col relative">
      <span
        data-aos="fade-up"
        data-aos-delay="400"
        class="font-weight-600 text-1.8"
        >CHANGE YOUR LIFE</span
      >
      <div
        data-aos="fade-up"
        data-aos-delay="500"
        class="flex justify-center font-weight-600 text-2 mb-2"
      >
        <div class="flex flex-col items-center">
          <span class="">投資</span>
          <div
            data-aos="zoom-in-left"
            data-aos-delay="600"
            class="under-line"
          ></div>
        </div>
        <span>房東，不如投資</span>
        <div class="flex flex-col items-center">
          <span class="">自己</span>
          <div
            data-aos="zoom-in-right"
            data-aos-delay="600"
            class="under-line"
          ></div>
        </div>
      </div>
      <!-- <span
        data-aos="fade-up"
        data-aos-delay="1000"
        class="text-1 tracking-wider"
        >首購族笑了，租得起就買得起</span
      > -->
      <div
        data-aos="fade-up"
        data-aos-delay="600"
        class="text-1 tracking-wider"
      >
        <span>首購族笑了，租得起就買得起</span>
      </div>

      <div class="flex justify-center mt-20">
        <div class="mx-2 flex flex-col">
          <img
            data-aos="fade"
            data-aos-delay="100"
            src="./s2/pc_list_1.jpg"
            alt=""
            style="width: 13vw; height: 13vw"
            class="cursor-pointer"
            @mouseenter="mouseEnter('iconStatus1')"
            @mouseout="mouseOut('iconStatus1')"
          />
          <img
            style="width: 9vw"
            :class="iconStatus1 ? 'slideDown' : 'slideUp'"
            class="self-center opacity-0"
            src="./s2/pc_list_1_h.svg"
            alt=""
          />
        </div>
        <div class="mx-2 flex flex-col">
          <img
            data-aos="fade"
            data-aos-delay="300"
            src="./s2/pc_list_2.jpg"
            alt=""
            style="width: 13vw; height: 13vw"
            class="cursor-pointer"
            @mouseenter="mouseEnter('iconStatus2')"
            @mouseout="mouseOut('iconStatus2')"
          />
          <img
            style="width: 9vw"
            :class="iconStatus2 ? 'slideDown' : 'slideUp'"
            class="self-center opacity-0"
            src="./s2/pc_list_2_h.svg"
            alt=""
          />
        </div>
        <div class="mx-2 flex flex-col">
          <img
            data-aos="fade"
            data-aos-delay="500"
            src="./s2/pc_list_3.jpg"
            alt=""
            style="width: 13vw; height: 13vw"
            class="cursor-pointer"
            @mouseenter="mouseEnter('iconStatus3')"
            @mouseout="mouseOut('iconStatus3')"
          />
          <img
            style="width: 9vw"
            :class="iconStatus3 ? 'slideDown' : 'slideUp'"
            class="self-center opacity-0"
            src="./s2/pc_list_3_h.svg"
            alt=""
          />
        </div>
        <div class="mx-2 flex flex-col">
          <img
            data-aos="fade"
            data-aos-delay="700"
            src="./s2/pc_list_4.jpg"
            alt=""
            style="width: 13vw; height: 13vw"
            class="cursor-pointer"
            @mouseenter="mouseEnter('iconStatus4')"
            @mouseout="mouseOut('iconStatus4')"
          />
          <img
            style="width: 9vw"
            :class="iconStatus4 ? 'slideDown' : 'slideUp'"
            class="self-center opacity-0"
            src="./s2/pc_list_4_h.svg"
            alt=""
          />
        </div>
      </div>
      <div class="flex justify-center mt-10">
        <img src="./s2/pc_style.png" alt="" />
      </div>
    </div>

    <!-- Mobile -->
    <div v-if="isMobile" class="pb-10">
      <div class="text-center flex-col mb-12">
        <span
          data-aos="fade-up"
          data-aos-delay="400"
          class="font-weight-600 text-5.5 mb-1"
          >CHANGE YOUR LIFE</span
        >
        <div
          data-aos="fade-up"
          data-aos-delay="500"
          class="flex justify-center font-weight-600 text-7 mb-1"
        >
          <div class="flex flex-col items-center">
            <span class="">投資</span>
            <div
              data-aos="zoom-in-left"
              data-aos-delay="600"
              class="mb-under-line"
            ></div>
          </div>
          <span>房東，不如投資</span>
          <div class="flex flex-col items-center">
            <span class="">自己</span>
            <div
              data-aos="zoom-in-right"
              data-aos-delay="600"
              class="mb-under-line"
            ></div>
          </div>
        </div>
        <!-- <div
          data-aos="fade-up"
          data-aos-delay="400"
          class="font-weight-600 text-7 mb-1"
        >
          <span style="border-bottom: solid 0.7vw; tracking-wide">投資</span>
          <span>房東，不如投資</span>
          <span style="border-bottom: solid 0.7vw">自己</span>
        </div> -->
        <div
          data-aos="fade-up"
          data-aos-delay="600"
          class="text-4 tracking-wider"
        >
          <span>首購族笑了，租得起就買得起</span>
        </div>
      </div>
      <div class="flex justify-center">
        <transition mode="out-in">
          <div
            @click="
              clickEvent()
              iconStatus1 = true
            "
            v-if="!iconStatus1"
          >
            <img
              src="./s2/pc_list_1.jpg"
              alt=""
              class="mr-1"
              style="height: 40vw; width: 40vw"
            />
          </div>
          <img
            @click="
              clickEvent()
              iconStatus1 = false
            "
            v-if="iconStatus1"
            src="./s2/mb_list_1_h.jpg"
            alt=""
            class="mr-1"
            style="height: 40vw; width: 40vw"
          />
        </transition>

        <transition mode="out-in">
          <div
            @click="
              clickEvent()
              iconStatus2 = true
            "
            v-if="!iconStatus2"
          >
            <img
              src="./s2/pc_list_2.jpg"
              alt=""
              class="mr-1"
              style="height: 40vw; width: 40vw"
            />
          </div>
          <img
            @click="
              clickEvent()
              iconStatus2 = false
            "
            v-if="iconStatus2"
            src="./s2/mb_list_2_h.jpg"
            alt=""
            class="mr-1"
            style="height: 40vw; width: 40vw"
          />
        </transition>
      </div>
      <div class="flex justify-center mt-2">
        <transition mode="out-in">
          <div
            @click="
              clickEvent()
              iconStatus3 = true
            "
            v-if="!iconStatus3"
          >
            <img
              src="./s2/pc_list_3.jpg"
              alt=""
              class="mr-1"
              style="height: 40vw; width: 40vw"
            />
          </div>
          <img
            @click="
              clickEvent()
              iconStatus3 = false
            "
            v-if="iconStatus3"
            src="./s2/mb_list_3_h.jpg"
            alt=""
            class="mr-1"
            style="height: 40vw; width: 40vw"
          />
        </transition>

        <transition mode="out-in">
          <div
            @click="
              clickEvent()
              iconStatus4 = true
            "
            v-if="!iconStatus4"
          >
            <img
              src="./s2/pc_list_4.jpg"
              alt=""
              class="mr-1"
              style="height: 40vw; width: 40vw"
            />
          </div>
          <img
            @click="
              clickEvent()
              iconStatus4 = false
            "
            v-if="iconStatus4"
            src="./s2/mb_list_4_h.jpg"
            alt=""
            class="mr-1"
            style="height: 40vw; width: 40vw"
          />
        </transition>
      </div>
      <div class="mt-10">
        <img src="./s2/pc_style.png" alt="" class="mx-auto" />
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import '@/assets/style/function.scss';
.slideDown {
  animation: slideDown 0.5s;
  animation-fill-mode: forwards;
}
@keyframes slideDown {
  from {
    transform: translateY(0);
    opacity: 0;
  }
  to {
    transform: translateY(40px);
    opacity: 100;
  }
}
.slideUp {
  animation: slideUp 0.5s;
  animation-fill-mode: forwards;
}
@keyframes slideUp {
  from {
    transform: translateY(40px);
    opacity: 100;
  }
  to {
    transform: translateY(0);
    opacity: 0;
  }
}
.v-leave {
  opacity: 1;
}
.v-leave-active {
  transition: opacity 0.6s;
}
.v-leave-to {
  opacity: 0;
}
.v-enter {
  opacity: 0;
}
.v-enter-active {
  transition: opacity 0.6s;
}
.v-enter-to {
  opacity: 1;
}
/* 螢幕尺寸標準 */
.under-line {
  background-color: black;
  height: 0.02vw;
  width: 3vw;
}

// Mobile
.mb-under-line {
  background-color: black;
  height: 0.3vw;
  width: 11vw;
}
/* 平板尺寸 */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
}

/* 手機尺寸 */
@media only screen and (max-width: 767px) {
  .section2{padding-top: 8vw;}
}

// 避免內容電腦過渡平板時，設計未考量的調整
@media only screen and (min-width: 1025px) and (max-width: 1199.98px) {
}

// 避免過度到 1280 x 720 時，設計未考量的調整
@media only screen and (min-width: 1025px) and (max-width: 1280px) {
}
</style>

<script>
// @ is an alias to /src
import { isMobile } from '@/utils'

export default {
  name: 'section2',

  data() {
    return {
      isMobile,
      iconStatus1: false,
      iconStatus2: false,
      iconStatus3: false,
      iconStatus4: false,
    }
  },

  methods: {
    clickEvent() {
      this.iconStatus1 = false
      this.iconStatus2 = false
      this.iconStatus3 = false
      this.iconStatus4 = false
    },
    mouseEnter(iconKey) {
      this[iconKey] = true
    },
    mouseOut(iconKey) {
      this[iconKey] = false
    },
  },

  mounted() {
    let interval
    // interval = setInterval(() => {
    //   this.setAnimateToEn()
    // }, 1000)

    setTimeout(() => {
      clearInterval(interval)
    }, 500)
  },

  created() {},
}
</script>
