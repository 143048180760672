<template>
  <div v-if="isMobile">
    <img
      v-show="!isShowMenu"
      src="../projects/ryc/s1/mobile_menu.svg"
      alt=""
      class="w-1/10 mobile-menu-icon"
      @click="toggleMenu()"
    />
    <img
      v-show="isShowMenu"
      src="../projects/ryc/s1/mobile_close.svg"
      alt=""
      class="w-1/10 mobile-menu-icon"
      style="z-index: 99999999"
      @click="toggleMenu()"
    />
    <ul
      class="mobile-menu bg-white opacity-90 fixed top-0 bottom-0 left-0 right-0 font-weight-900 text-lg"
      style="padding-top: 65%"
      :class="isShowMenu ? 'active_menu-in' : 'active_menu-out'"
      @click="toggleMenu()"
    >
      <li
        class="mx-10 pb-3 mb-3"
        style="border-bottom: 1px solid#676767"
        v-scroll-to="{
          element: `#section2`,
          offset: 0,
          onDone: onDoneScrollTo,
        }"
      >
        美好入心
      </li>
      <li
        class="mx-10 pb-3 mb-3"
        style="border-bottom: 1px solid#676767"
        v-scroll-to="{
          element: `#section4`,
          offset: 0,
          onDone: onDoneScrollTo,
        }"
      >
        水岸富翁
      </li>
      <li
        class="mx-10 pb-3 mb-3"
        style="border-bottom: 1px solid#676767"
        v-scroll-to="{
          element: `#section6`,
          offset: -0,
          onDone: onDoneScrollTo,
        }"
      >
        花樣生活
      </li>
      <li
        class="mx-10 pb-3 mb-3"
        style="border-bottom: 1px solid#676767"
        v-scroll-to="{
          element: `#section8`,
          offset: -10,
          onDone: onDoneScrollTo,
        }"
      >
        捷運熱點
      </li>
      <li
        class="mx-10 pb-3 mb-3"
        v-scroll-to="{
          element: `#section9`,
          offset: 0,
          onDone: onDoneScrollTo,
        }"
      >
        空間自由
      </li>
    </ul>
    <!--  -->
    <div :class="{ 'mobile-nav': true }">
      <a class="nav-item" @click="showCallDialog">
        <font-awesome-icon icon="phone-alt" />
        <div class="label">撥打電話</div>
      </a>

      <a class="nav-item" @click="showMessengerDialog">
        <font-awesome-icon :icon="['fab', 'facebook-messenger']" />
        <div class="label">FB諮詢</div>
      </a>
      <a
        class="nav-item bt_registration"
        v-scroll-to="{ element: `#contact`, offset: 0 }"
      >
        <font-awesome-icon icon="home" />
        <div class="label">預約賞屋</div>
      </a>
      <a class="nav-item" @click="showMapDialog">
        <font-awesome-icon icon="map-marker-alt" />
        <div class="label">地圖導航</div>
      </a>
      <el-dialog
        title
        :visible.sync="isShowCallDialog"
        width="90%"
        :modal-append-to-body="false"
      >
        <CallDialog :phone="info.phone" />
      </el-dialog>
      <el-dialog
        title
        :visible.sync="isShowMessengerDialog"
        width="90%"
        :modal-append-to-body="false"
      >
        <MessengerDialog :messenger="info.fbMessage" />
      </el-dialog>
      <el-dialog
        title
        :visible.sync="isShowMapDialog"
        width="90%"
        :modal-append-to-body="false"
      >
        <MapDialog :link="info.googleLink" :address="info.address" />
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { isMobile, isTablet } from '@/utils'
import CallDialog from '@/components/Dialog/Call'
import MessengerDialog from '@/components/Dialog/Messenger'
import MapDialog from '@/components/Dialog/Map'
import info from '@/info'

export default {
  name: 'mobileNav',
  components: {
    CallDialog,
    MessengerDialog,
    MapDialog,
  },
  data() {
    return {
      info,

      isMobile,
      isTablet,
      isBottom: false,
      isShowCallDialog: false,
      isShowMessengerDialog: false,
      isShowMapDialog: false,
      isShowMenu: false,
    }
  },
  computed: {
    offset() {
      if (this.isMobile) {
        return -100
      }

      if (this.isTablet) {
        return 0
      }

      return 0
    },
  },
  methods: {
    showCallDialog() {
      this.isShowCallDialog = true
    },
    showMessengerDialog() {
      this.isShowMessengerDialog = true
    },

    showMapDialog() {
      this.isShowMapDialog = true
    },

    toggleMenu() {
      this.isShowMenu = !this.isShowMenu
    },

    onDoneScrollTo() {
      this.isShowMenu = false
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/variableColor.scss';
.mobile-menu {
  transform: translateX(100vw);
}
.mobile-menu-icon {
  position: fixed;
  top: 3%;
  right: 3%;
}
.mobile-nav {
  width: 100%;
  height: 63px;
  background: $mobile_nav_background;

  display: flex;
  position: fixed;
  bottom: 0;
  z-index: 1000;
  // box-shadow: 0 -2px 5px 0 #000;
  display: none;
  transition: all 0.5s;

  .nav-item {
    flex: 1;
    color: $mobile_nav_color;
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-right: 1px solid $mobile_nav_border;

    &:nth-child(4) {
      border-right: none;
    }
  }

  svg {
    width: 20px !important;
    height: 25px;
    color: $mobile_nav_icon;
  }

  .label {
    text-align: center;
  }
}

.active_menu-in {
  animation: translateX-in 1s;
  animation-fill-mode: forwards;
}
.active_menu-out {
  animation: translateX-out 1s;
  animation-fill-mode: forwards;
}
@keyframes translateX-in {
  from {
    transform: translateX(100vw);
  }

  to {
    transform: translateX(0vw);
  }
}

@keyframes translateX-out {
  from {
    transform: translateX(0vw);
  }

  to {
    transform: translateX(100vw);
  }
}
/* 手機尺寸 */
@media only screen and (max-width: 767px) {
  .mobile-nav {
    display: flex;
  }
}
</style>
