<template>
  <div id="section8" class="section8">
    <div v-if="!isMobile">
      <div data-aos="fade-up" data-aos-delay="400" class="flex-col">
        <div
          data-aos="fade-up"
          data-aos-delay="400"
          class="flex justify-center mb-4"
        >
          <img src="./s8/pc_style_line.svg" alt="" style="width: 8%" />
          <span class="font-weight-600 text-1.3 mx-4">CONVENIENT MRT</span>
          <img src="./s8/pc_style_line.svg" alt="" style="width: 8%" />
        </div>
        <span
          data-aos="fade-up"
          data-aos-delay="500"
          class="font-weight-600 text-1.8 tracking-widest"
          >全城在線 就等你</span
        >
        <div
          data-aos="fade-up"
          data-aos-delay="600"
          class="font-weight-500 text-1.1 tracking-widest leading-loose my-8"
        >
          <p>從步出「睿暘沁」家門輕快抵達捷運橘線先嗇宮站</p>
          <p>1站直達三重站，2站接軌台北車站六鐵交通網</p>
          <p>民權西路、松江南京、忠孝新生等北市核心站點一線串連不必轉乘</p>
        </div>
      </div>
      <div data-aos="fade" data-aos-delay="400" id="swiper_container">
        <swiper
          data-aos="fade"
          data-aos-delay="400"
          :options="swiperOptions"
          ref="swiperSection8"
          @slideChangeTransitionStart="slidechange"
        >
          <swiper-slide
            class="slide"
            v-for="(slide, i) in slides"
            v-bind:key="i"
            v-bind:style="{
              backgroundImage: `url(${slide.img})`,
            }"
          >
            <div class="caption">
              {{ slide.caption }}
            </div>
          </swiper-slide>
        </swiper>

        <div
          class="flex justify-between mt-5"
          style="width: 100vw; padding: 0 5%"
        >
          <img
            @click="prev()"
            class="w-12 cursor-pointer"
            src="./s8/pc_arrow_l.svg"
            alt=""
          />
          <div class="swiper-pagination" slot="pagination"></div>
          <img
            @click="next()"
            class="w-12 cursor-pointer"
            src="./s8/pc_arrow_r.svg"
            alt=""
          />
        </div>
      </div>
    </div>

    <!-- Mobile -->
    <div v-if="isMobile" class="mb-6">
      <div class="flex-col">
        <div
          data-aos="fade-up"
          data-aos-delay="400"
          class="flex justify-center"
        >
          <img src="./s8/pc_style_line.svg" alt="" style="width: 12%" />
          <span class="font-weight-600 text-6 mx-3">CONVENIENT MRT</span>
          <img src="./s8/pc_style_line.svg" alt="" style="width: 12%" />
        </div>
        <span
          data-aos="fade-up"
          data-aos-delay="500"
          class="font-weight-600 text-6 tracking-widest"
          >全城在線 就等你</span
        >
        <div
          data-aos="fade-up"
          data-aos-delay="600"
          class="font-weight-500 text-4 tracking-wider leading-loose my-6"
        >
          <p class="mx-auto" style="width: 50%">
            從步出「睿暘沁」家門輕快抵達捷運橘線先嗇宮站
          </p>
          <p class="mx-auto" style="width: 89%">
            1站直達三重站，2站接軌台北車站六鐵交通網
            民權西路、松江南京、忠孝新生等北市核心站點一線串連不必轉乘
          </p>
        </div>
      </div>

      <div data-aos="fade" data-aos-delay="400">
        <swiper
          :options="swiperOptions"
          ref="swiperSection8"
          @slideChangeTransitionStart="slidechange"
        >
          <swiper-slide
            class="slide"
            v-for="(slide, i) in slides"
            v-bind:key="i"
            v-bind:style="{
              backgroundImage: `url(${slide.img})`,
            }"
          >
            <div class="caption">
              {{ slide.caption }}
            </div>
          </swiper-slide>
        </swiper>

        <div class="flex justify-between mt-5 px-8" style="width: 100vw">
          <img
            @click="prev()"
            style="width: 8vw"
            class="w-12 cursor-pointer"
            src="./s8/pc_arrow_l.svg"
            alt=""
          />
          <div class="swiper-pagination" slot="pagination"></div>
          <img
            @click="next()"
            style="width: 8vw"
            class="w-12 cursor-pointer"
            src="./s8/pc_arrow_r.svg"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import '@/assets/style/function.scss';
/* 螢幕尺寸標準 */
.section8{
      margin-bottom: 20px;
}
.swiper-container {
  height:calc(810 * 100vw / 1920);
  width:100%;
  margin: 0;
  .slide {
    height: 100%;
    width: 100%;
    background-size: cover;
    .caption {
      position: absolute;
      z-index: 1;
      color: #fff;
      right: 2vw;
      bottom: 0.8vw;
      font-size: 1.2vw;
      font-weight: bold;
      text-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    }
  }
  .swiper-button-prev {
    // left: size(500);
    left: 0;
    bottom: 0;
    width: size(80);
    height: size(80);
    color: #fff;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left;
    // background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E") !important;

    &::after {
      content: '';
    }
  }
  .swiper-button-next {
    right: 0;
    color: #fff;
    width: size(80);
    height: size(80);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right;
    // background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E") !important;

    &::after {
      content: '';
    }
  }
}
::v-deep .swiper-pagination {
  position: relative;
  .swiper-pagination-bullet {
    margin: 0 10px;
    border-radius: 50%;
    background: transparent;
    border: 1px solid black;
    width: size(9);
    height: size(9);
    opacity: 1;
    &.swiper-pagination-bullet-active {
      background: black;
    }
  }
}
/* 平板尺寸 */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
}

/* 手機尺寸 */
@media only screen and (max-width: 767px) {
  .swiper-container {
    height:calc(247 * 100vw / 375);
    width: 100vw;
    margin: 0;
    .slide {
      height: 100%;
      width: 100%;
      background-size: cover;
      .caption {
        position: absolute;
        z-index: 1;
        color: #fff;
        right: 1vw;
        bottom: 0.8vw;
        font-size: 3.2vw;
        font-weight: bold;
        text-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
      }
    }
    .swiper-button-prev {
      // left: size(500);
      left: 0;
      bottom: 0;
      width: size(80);
      height: size(80);
      color: #fff;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: left;
      // background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E") !important;

      &::after {
        content: '';
      }
    }
    .swiper-button-next {
      right: 0;
      color: #fff;
      width: size(80);
      height: size(80);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: right;
      // background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E") !important;

      &::after {
        content: '';
      }
    }
  }
  ::v-deep .swiper-pagination {
    position: relative;
    .swiper-pagination-bullet {
      margin: 0 10px;
      border-radius: 50%;
      background: transparent;
      border: 1px solid black;
      width: size(28);
      height: size(28);
      opacity: 1;
      &.swiper-pagination-bullet-active {
        background: black;
      }
    }
  }
}

// 避免內容電腦過渡平板時，設計未考量的調整
@media only screen and (min-width: 1025px) and (max-width: 1199.98px) {
}

// 避免過度到 1280 x 720 時，設計未考量的調整
@media only screen and (min-width: 1025px) and (max-width: 1280px) {
}
</style>

<script>
// @ is an alias to /src
import { isMobile } from '@/utils'
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  name: 'section8',

  components: {
    Swiper,
    SwiperSlide,
  },
  directives: {
    swiper: directive,
  },
  data() {
    return {
      isMobile,
      activeSlide: {},
      title: '／亞東生活圈 都心璀璨一等地／',
      content: `近亞東醫院站、湳雅夜市、台65線<br/>遠東科技園區 Google、亞馬遜國際企業插旗<br/>更近板泰輕軌預劃路線 連袂鼎起最耀眼將來`,
      slides: [
        {
          img: isMobile
            ? require('@/projects/ryc/s8/mb_slider_1.jpg')
            : require('@/projects/ryc/s8/pc_slider_1.jpg'),
          caption: '捷運先嗇宮站',
        },
        {
          img: isMobile
            ? require('@/projects/ryc/s8/mb_slider_2.jpg')
            : require('@/projects/ryc/s8/pc_slider_2.jpg'),
          caption: '64快速道路',
        },
        {
          img: isMobile
            ? require('@/projects/ryc/s8/mb_slider_3.jpg')
            : require('@/projects/ryc/s8/pc_slider_3.jpg'),
          caption: '台一線',
        },
      ],
      swiperOptions: {
        effect: 'fade',
        speed: 1200,
        spaceBetween: 30,
        loop: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        pagination: {
          el: '.section8 .swiper-pagination',
          clickable: true,
        },
        // autoplay: {
        //   delay: 4500,
        //   disableOnInteraction: false,
        // },
      },
    }
  },

  methods: {
    slidechange() {
      const activeIndex = this.$refs.swiperSection8.$swiper.realIndex
      this.activeSlide = this.slides[activeIndex]
    },
    prev() {
      this.$refs.swiperSection8.$swiper.slidePrev()
    },
    next() {
      this.$refs.swiperSection8.$swiper.slideNext()
    },
  },

  mounted() {
    this.activeSlide = this.slides[0]
  },
}
</script>
