<template>
  <div id="section6" class="section6">
    <img
      v-if="!isMobile"
      class="w-full h-auto object-cover"
      style="margin-bottom: 10%"
      src="./s6/pc_img.jpg"
      alt=""
    />
    <div v-if="isMobile" class="overflow-x-auto">
      <img src="./s6/mb_title_img.jpg" class="w-screen relative" alt="" />
      <span class="text-3 font-weight-600 absolute bottom-62 left-0 right-0"
        >點擊照片放大</span
      >
      <div class="relative">
        <img
          @click="openBigImg()"
          class="w-screen"
          src="./s6/mb_img.jpg"
          alt=""
        />
        <img
          class="w-10 absolute bottom-3 blink"
          style="left: 45%"
          src="./s6/mb_hand.svg"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { isMobile } from '@/utils'

export default {
  name: 'section6',

  data() {
    return {
      isMobile,
      bigImgMode: false,
    }
  },

  methods: {
    openBigImg() {
      this.$emit('openBigImg')
    },
  },
  mounted() {},

  created() {},
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/function.scss';
@import '@/assets/style/animate.scss';
.blink {
  @include animate(fadeIn, 1s, 0);
  animation-iteration-count: infinite;
  animation-direction: alternate;
  pointer-events: none;
}
/* 螢幕尺寸標準 */
/* 平板尺寸 */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
}

/* 手機尺寸 */
@media only screen and (max-width: 767px) {
}

// 避免內容電腦過渡平板時，設計未考量的調整
@media only screen and (min-width: 1025px) and (max-width: 1199.98px) {
}

// 避免過度到 1280 x 720 時，設計未考量的調整
@media only screen and (min-width: 1025px) and (max-width: 1280px) {
}
</style>
