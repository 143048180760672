import { render, staticRenderFns } from "./section4.vue?vue&type=template&id=3b3dec01&scoped=true&"
import script from "./section4.vue?vue&type=script&lang=js&"
export * from "./section4.vue?vue&type=script&lang=js&"
import style0 from "./section4.vue?vue&type=style&index=0&id=3b3dec01&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b3dec01",
  null
  
)

export default component.exports