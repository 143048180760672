<template>
  <div class="" id="house-info">
    <div v-if="!isMobile" class="bg-footer relative" style="height: 19vw">
      <div class="absolute top-9 right-0 left-0">
        <span class="text-white font-weight-700 text-1.3">建案資訊</span>
        <div
          class="flex justify-center text-white font-weight-700 text-1.1 mt-10"
        >
          <div class="flex flex-col" style="margin-right: 13%">
            <div class="flex items-center">
              <div class="line-left"></div>
              <span class="text-left my-3 pl-2">投資興建：睿暘建設</span>
            </div>
            <div class="flex items-center">
              <div class="line-left"></div>
              <span class="text-left my-3 pl-2">建築營造：威騰營造</span>
            </div>
            <div class="flex items-center">
              <div class="line-left"></div>
              <span class="text-left my-3 pl-2"
                >建築規劃：林峰池建築師事務所</span
              >
            </div>
          </div>
          <div class="flex flex-col">
            <div class="flex items-center">
              <div class="line-left"></div>
              <span class="text-left my-3 pl-2"
                >不動產經紀人：鄭馨怡(104)新北經字第003167號</span
              >
            </div>
            <div class="flex items-center">
              <div class="line-left"></div>
              <span class="text-left my-3 pl-2">企劃銷售：海沃創意行銷</span>
            </div>
            <div class="flex items-center">
              <div class="line-left"></div>
              <span class="text-left my-3 pl-2"
                >建築執照：109重建字第00388號</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Mobile -->
    <div
      v-if="isMobile"
      class="mb-bg-footer"
      style="height: 95vw; padding-top: 10%"
    >
      <span class="text-white font-weight-700 text-6 mx-auto tracking-widest"
        >建案資訊</span
      >
      <div
        class="flex flex-col text-left text-3.5 text-white tracking-wider font-weight-700 pl-6 pt-5"
      >
        <div class="flex items-center my-2">
          <div class="mb-line-left mr-2 mb-1"></div>
          <span>投資興建：睿暘建設</span>
        </div>
        <div class="flex items-center my-2">
          <div class="mb-line-left mr-2 mb-1"></div>
          <span>不動產經紀人：鄭馨怡(104)新北經字第003167號</span>
        </div>
        <div class="flex items-center my-2">
          <div class="mb-line-left mr-2 mb-1"></div>
          <span>建築營造：威騰營造</span>
        </div>
        <div class="flex items-center my-2">
          <div class="mb-line-left mr-2 mb-1"></div>
          <span>企劃銷售：海沃創意行銷</span>
        </div>
        <div class="flex items-center my-2">
          <div class="mb-line-left mr-2 mb-1"></div>
          <span>建築規劃：林峰池建築師事務所</span>
        </div>
        <div class="flex items-center my-2">
          <div class="mb-line-left mr-2 mb-1"></div>
          <span>建築執照：109重建字第00388號</span>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import info from '@/info'
import Footer from '@/layouts/Footer.vue'
import { isMobile } from '@/utils'
export default {
  name: 'houseInfo',
  components: {
    Footer,
  },
  data() {
    return {
      isMobile,
      houseInfos: info.houseInfos,
    }
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
.bg-footer {
  background-image: url('../projects/ryc/footer/pc_bg.jpg');
  background-repeat: no-repeat;
  background-size: 100%;
}
.line-left {
  background-color: white;
  height: 1vw;
  width: 0.2vw;
}

// Mobile
.mb-bg-footer {
  background-image: url('../projects/ryc/footer/mb_bg.jpg');
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: 0vh 0vh;
}
.mb-line-left {
  background-color: white;
  height: 5.5vw;
  width: 1vw;
}
</style>
